import React, { useEffect, useState } from "react";
import PageHeader from "../../shared/components/page/page-header/PageHeader";
import EditRelationship from "./edit-relationship/EditRelationship";
import { Relationship } from "../../shared/models/relationship.model";
import { RelationshipService } from "../../shared/services/relationships/relationshipService";
import useAppStore from "../../appStore";
import RelationshipCard from "./relationship-card-list/RelationshipCard";
import RelationshipFilterDropdown from "./relationship-card-list/components/RelationshipFilterDropdown";
import { Employee } from "../../shared/models/employee.model";
import Box from "@mui/material/Box";
import CustomLoadingDots from "../../shared/components/page/popup-dialog/CustomLoadingDots";

const RelationshipPage: React.FC = () => {

  const {loggedInUser, masterRefresh, toggleMasterRefresh} = useAppStore();
  const [relationships, setRelationships] = useState<Relationship[]>([]);
  const [selected, setSelected] = useState<Relationship | null>(null)
  const [open, setOpen] = useState(false);
  const [employeeToFilter, setEmployeeToFilter] = useState<Employee | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
      const fetchRelationships = async () => {
        try {
          setIsLoading(true);
          const service = new RelationshipService();
          const data = await service.getRelationships(loggedInUser.organization?.id, employeeToFilter?.id);
          setRelationships(data);
        } catch (error) {
          console.error('Error fetching relationships:', error);
        } finally {
          setIsLoading(false);
        }
      };

      fetchRelationships().then();

  }, [masterRefresh, employeeToFilter]);

  const handleClose = () => {
    setOpen(false);
    setSelected(null);
  }

  const handleNew = () => {
    setSelected(new Relationship());
    setOpen(true);
  }

  const handleSave = async (item) => {
    try {
      const isCreating = item.id === 0;
      if (isCreating) {
        item.created_by = loggedInUser.employee;
      }
      const service = new RelationshipService();
      const response = isCreating
        ? await service.createRelationship(item)
        : await service.updateRelationship(item);
    } catch (e) {
      console.error('Error saving relationship:', e);
    } finally {
      handleClose();
      toggleMasterRefresh();
    }
  }

  return (
    <div style={{marginTop: '-40px'}}>
      <PageHeader title="Relationships" showAction={true} actionButtonName="New Relationship" onActionClicked={handleNew} />
      <Box sx={{marginBottom: '100px'}}>
        <RelationshipFilterDropdown filter={employeeToFilter} setFilter={setEmployeeToFilter} />
        {isLoading &&
         <CustomLoadingDots />
        }
        {relationships.map((item, index) => (
          <RelationshipCard
            key={index}
            relationship={item}
            setSelected={setSelected}
            setOpen={setOpen}
          />
        ))}
      </Box>
        {open && (
          <EditRelationship open={open} onClose={handleClose} onSave={handleSave} itemToEdit={selected} />
        )}
    </div>
  );
};

export default RelationshipPage;