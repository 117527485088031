import { useState, useEffect } from 'react';
import { useWorkItem } from "../../../../work-items/context/workItemContext";
import { calculateProgress } from "../../../../../shared/utils/table-modifier-util/tableDataUtil";
import { WorkItem } from "../../../../../shared/models/workItem.model";
import { WorkItemsService } from "../../../../../shared/services/work-items/workItemsService";

function useWorkItemCard(workItem, toggleRefresh) {
  const { setWorkItemToEdit, workItemToEdit, open, setOpen } = useWorkItem();

  const [photos, setPhotos] = useState([]);
  const [commentsOpen, setCommentsOpen] = useState(false);
  const [detailsOpen, setDetailsOpen] = useState(false);
  const [closeOpen, setCloseOpen] = useState(false);
  const [confirmNotifyOpen, setConfirmNotifyOpen] = useState(false);
  const { value, colorClass, label } = calculateProgress(workItem.urgency);


  useEffect(() => {
    setPhotos(workItem.media.filter(media => media.url).map(media => media.url));
  }, [workItem.media]);

  const handleWorkItemEditorAction = (action) => {
    setOpen(false);
    setCloseOpen(false);
    setWorkItemToEdit(new WorkItem());
  };

  const handleEditWorkItem = () => {
    setWorkItemToEdit(workItem);
    setOpen(true);
  };

  const handleCloseClicked = () => {
    setWorkItemToEdit(workItem);
    setCloseOpen(true);
  }

  const handleCloseWorkItem = async () => {
    try {
      const service = new WorkItemsService();
      workItemToEdit.status = 'Closed';
      const response = await service.updateWorkItem(workItemToEdit);
      handleWorkItemEditorAction('save')
    } catch (e) {
      console.error('Error in closing:', e);
      console.log(`Failed to close work item: ${e instanceof Error ? e.message : JSON.stringify(e)}`);
    } finally {
      toggleRefresh();
    }
  };

  const handleConfirmNotify = async () => {
    if (workItem) {
      try {
        // await workItemService.notify(workItem.id);
        setConfirmNotifyOpen(false);
        toggleRefresh();
      } catch (error) {
        console.error(error);
      }
    }
  };

  const handleNotifyClicked = () => {
    setConfirmNotifyOpen(true);
  };

  const handlerWorkItemEditorAction = (action) => {
    setOpen(false);
    setWorkItemToEdit(new WorkItem());
  };

  return {
    photos,
    commentsOpen,
    setCommentsOpen,
    detailsOpen,
    setDetailsOpen,
    confirmNotifyOpen,
    setConfirmNotifyOpen,
    handleEditWorkItem,
    handleConfirmNotify,
    handleNotifyClicked,
    handleWorkItemEditorAction,
    closeOpen, setCloseOpen,
    handleCloseWorkItem, handleCloseClicked,
    handlerWorkItemEditorAction,
    value, colorClass, label
  };
}

export default useWorkItemCard;
