import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useAppStore from "../../../../app/appStore";
import { IconButton } from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import { Close } from "@mui/icons-material";
import ModalTransition from "../../../../app/shared/components/page/transitions/ModalTransition";
import Dialog from "@mui/material/Dialog";

const MobileLaunchMenu = () => {
  const MenuState = {
    MAIN: 'main',
    SETTINGS: 'settings',
    ANALYTICS: 'analytics'
  };

  const { loggedInUser, whiteLabelProps, appImages } = useAppStore();
  const [menuState, setMenuState] = useState(MenuState.MAIN);
  const [isExpanded, setIsExpanded] = useState(false);
  const navigate = useNavigate();

  const handleChange = (newValue) => {
    switch (newValue) {
      case 'menu':
        setIsExpanded(!isExpanded);
        break;
      case 'admin':
        setMenuState(MenuState.SETTINGS);
        break;
      case 'analytics':
        setMenuState(MenuState.ANALYTICS);
        break;
      case 'back':
        setMenuState(MenuState.MAIN);
        break;
      default:
        navigatePath(newValue);
        setIsExpanded(false);
        setMenuState(MenuState.MAIN);
    }
  };

  const navigatePath = (newValue) => {
    const paths = {
      home: '/dashboard',
      inspections: '/facilities/inspections',
      workItems: '/facilities/work-items',
      org: '/team/org-chart',
      cases: '/facilities/cases',
      safety: '/ehs/home',
      analytics: '/mobile-analytics',
      employees: '/team/employees',
      equipment: '/admin/equipment',
      supplies: '/admin/supplies',
      locationProfile: '/admin/location-profile',
      charts: '/inspection-analytics',
      overview: '/site-health',
      surveyResults: '/survey-analytics',
      surveys: '/facilities/surveys',
      summary: '/location-summary',
      relationships: '/crm/relationships',
    };
    if (paths[newValue]) navigate(paths[newValue]);
  };

  const getNavItems = () => {
    switch (menuState) {
      case MenuState.SETTINGS:
        return [
          { label: "", value: "back", imgSrc: appImages.iconGoBack, imgWidth: '50px', imgHeight: '50px' },
          { label: "", value: "admin", imgSrc: appImages.iconAdmin, imgWidth: '88px', imgHeight: '74px' },
          { label: "Employees", value: "employees", imgSrc: '', imgWidth: '50px', imgHeight: '50px' },
          { label: "Supplies", value: "supplies", imgSrc: '', imgWidth: '50px', imgHeight: '50px' },
          { label: "Surveys", value: "surveys", imgSrc: '', imgWidth: '50px', imgHeight: '50px' },
          { label: "Location Profile", value: "locationProfile", imgSrc: '', imgWidth: '50px', imgHeight: '50px' }
        ];
      case MenuState.ANALYTICS:
        return [
          { label: "", value: "back", imgSrc: appImages.iconGoBack, imgWidth: '50px', imgHeight: '50px' },
          { label: "", value: "analytics", imgSrc: appImages.iconAnalytics, imgWidth: '72px', imgHeight: '72px' },
          { label: "Corporate Overview", value: "overview", imgSrc: '', imgWidth: '50px', imgHeight: '50px' },
          { label: "Inspection Charts", value: "charts", imgSrc: '', imgWidth: '50px', imgHeight: '50px' },
          { label: "Survey Results", value: "surveyResults", imgSrc: '', imgWidth: '50px', imgHeight: '50px' }
        ];
      default:
        return [
          { label: "Home", value: "home", imgSrc: appImages.iconHome, imgWidth: '68px', imgHeight: '61px' },
          ...(loggedInUser?.permissions?.hasAccess("Location Summary") ? [{ label: "Location Summary", value: "summary", imgSrc: appImages.iconSummary, imgWidth: '55px', imgHeight: '65px' }] : []),
          ...(loggedInUser?.permissions?.hasAccess("Inspections") ? [{ label: "Inspections", value: "inspections", imgSrc: appImages.iconInspectionAlt2, imgWidth: '75px', imgHeight: '74px' }] : []),
          ...(loggedInUser?.permissions?.hasAccess("Work Items") ? [{ label: "Work Items", value: "workItems", imgSrc: appImages.iconWorkItems, imgWidth: '60px', imgHeight: '70px' }] : []),
          ...(loggedInUser?.permissions?.hasAccess("Cases") ? [{ label: "Cases", value: "cases", imgSrc: appImages.iconCases, imgWidth: '73px', imgHeight: '93px' }] : []),
          ...(loggedInUser?.permissions?.hasAccess("Relationship Management") ? [{ label: "Relationships", value: "relationships", imgSrc: appImages.iconRelationships, imgWidth: '73px', imgHeight: '52px' }] : []),
          ...(loggedInUser?.permissions?.hasAccess("Analytics") ? [{ label: "Analytics", value: "analytics", imgSrc: appImages.iconAnalytics, imgWidth: '78px', imgHeight: '78px' }] : []),
          { label: "Safety", value: "safety", imgSrc: appImages.iconSafety, imgWidth: '62px', imgHeight: '66px' },
          ...(loggedInUser?.permissions?.hasAccess("Org Chart") ? [{ label: "Org Chart", value: "org", imgSrc: appImages.iconOrgChart, imgWidth: '95px', imgHeight: '73px' }] : []),
          ...(loggedInUser?.permissions?.hasAccess("Admin") ? [{ label: "Admin", value: "admin", imgSrc: appImages.iconAdmin, imgWidth: '93px', imgHeight: '79px' }] : [])
        ];
    }
  };

  const navItems = getNavItems();

  return (
    <>
      <IconButton
        aria-label="menu"
        onClick={() => handleChange('menu')}
        sx={{
          position: 'fixed',
          bottom: 16,
          right: 16,
          zIndex: isExpanded ? 1000 : 999,
          width: 62,
          height: 61,
          padding: 0,
          boxShadow: '0px 7px 11px rgba(0, 0, 0, 0.33)',
          borderRadius: '50%',
          border: '2px solid #077c5e',
          backgroundColor: '#18aa79',
          '&:hover': {
            backgroundColor: '#18aa79',
          },
        }}
      >
        {isExpanded ? <Close sx={{ color: 'white' }} /> : <MenuIcon sx={{ color: 'white' }} />}
      </IconButton>
      <Dialog
        fullScreen
        open={isExpanded}
        onClose={undefined}
        TransitionComponent={ModalTransition}
        sx={{zIndex: 999}}
        PaperProps={{ style: { overflowX: 'hidden', backgroundColor: '#f9f9f9' } }}
      >
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-around',
            gap: '10px',
            padding: '10px',
            marginBottom: '80px',
          }}
        >
          {navItems.map((item) => (
            <div
              key={item.value}
              onClick={() => handleChange(item.value)}
              className="menu-item"
              style={{
                flex: '1 1 calc(50% - 10px)',
                maxWidth: 'calc(50% - 10px)',
                boxSizing: 'border-box',
                position: 'relative',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                border: '1px solid #f1f1f1',
                borderRadius: '2px',
                padding: '10px',
                cursor: 'pointer',
                minHeight: '150px',
                boxShadow: '0 2.5px 4px rgba(0, 0, 0, 0.2)',
                backgroundColor: !item.imgSrc ? whiteLabelProps.primary_color_light : '#f8f8f8',
              }}
            >
              {item.imgSrc && (
                <img
                  src={item.imgSrc}
                  alt={item.label}
                  style={{ width: item.imgWidth, height: item.imgHeight, marginBottom: '10px' }}
                />
              )}
              <span
                style={{
                  position: item.imgSrc ? 'absolute' : 'relative',
                  bottom: item.imgSrc ? '10px' : '',
                  fontSize: '14px',
                  color: item.imgSrc ? '' : 'white',
                }}
              >
            {item.label}
          </span>
            </div>
          ))}
        </div>
      </Dialog>
    </>
  );
};

export default MobileLaunchMenu;
