import React, { useEffect, useState } from "react";
import { Button, Divider, useMediaQuery, useTheme } from "@mui/material";
import WhereAndWhatRow from "./WhereAndWhatRow";
import EditWhereAndWhat from "./edit-where-and-what/EditWhereAndWhat";
import { useWorkItem } from "../../../../context/workItemContext";
import { WhereAndWhatModel } from "../../../../../../shared/models/whereAndWhat.model";

const WhereAndWhat = ({ fromInspection, createPrefill, isEditing }) => {

  const {
    workItemToEdit,
    setWorkItemToEdit,
  } = useWorkItem()

  const isMobile = useMediaQuery(useTheme().breakpoints.only('xs'));
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [whereAndWhat, setWhereAndWhat] = useState<WhereAndWhatModel[]>([])

  useEffect(() => {
    if (whereAndWhat.length === 0 && workItemToEdit.where_and_what.length !== 0) {
      setWhereAndWhat(workItemToEdit.where_and_what)
    }
  }, [workItemToEdit.where_and_what]);


  useEffect(() => {
      if (workItemToEdit.id === 0 && !fromInspection) {
        setDialogOpen(true)
      } else {
        setDialogOpen(false)
      }
  }, [workItemToEdit.id]);


  const handleOpenDialog = () => {
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const handleDelete = (index) => {
    const updatedWhereAndWhat = whereAndWhat.filter((_, i) => i !== index);
    setWhereAndWhat(updatedWhereAndWhat);
    setWorkItemToEdit((prevWorkItem) => ({
      ...prevWorkItem,
      where_and_what: updatedWhereAndWhat,
    }));
  };


  return (

    <>
      <div className="flex-column-fluid">
        <div className="flex-row-fluid pe-8">
          <div style={{marginBottom: '15px'}}>
            <h3 className="card-title align-items-start flex-column">
              <span className="card-label fw-bold text-gray-700">Where and What</span>
            </h3>
            <EditWhereAndWhat
              open={isDialogOpen}
              onClose={handleCloseDialog}
              whereAndWhat={whereAndWhat}
              setWhereAndWhat={setWhereAndWhat}
              createPrefill={createPrefill}
            />
          </div>
        </div>

        <div className="table-responsive flex-row-fluid mt-5 pe-3">
          <table className="table table-row-solid gs-0 gy-3 my-0" style={{borderBottom: '1px solid #c7c7c7'}}>
            <tbody>
            {whereAndWhat.length === 0 ? (
              <tr>
                <td colSpan={3} style={{ textAlign: "left", padding: "10px" }}>
                  Nothing specified
                </td>
              </tr>
            ) : (
              whereAndWhat.map((entry, index) => (
                <WhereAndWhatRow
                  key={index}
                  entry={entry}
                  isMobile={isMobile}
                  isEditing={isEditing}
                  onDelete={() => handleDelete(index)}
                />
              ))
            )}
            </tbody>

          </table>
          <div style={{ textAlign: 'left' }}>
            {isEditing &&
              <Button
                variant="text"
                color="primary"
                onClick={handleOpenDialog}
                style={{ marginTop: '10px', fontSize: '1.1rem' }}
              >
                ADD ...
              </Button>
            }
          </div>
        </div>
      </div>
    </>
  )

}

export default WhereAndWhat
