import httpClient from "../../../utils/http-clients/djangoHttpClient";
import useTracer from "../../../framework/tracer/tracer";
import { SurveySummarySerializer } from "../../../models/serializers/surveySummarySerializer";
import { Organization } from "../../../models/organization.model";
import { SurveyLocationListSerializer } from "../../../models/serializers/surveyLocationListSerializer";

export class CommandGetLocationSummaries {

    INSPECTIONS_SERVICE = process.env.REACT_APP_API_FACILITIES_SERVICE_URL;

    getUrl() {

        return `${this.INSPECTIONS_SERVICE}/analytics/`;
    }

    async run(organization: Organization) {

        const url = this.getUrl()

        const params = {
            params: {
                organization: organization.id,
                module: 'surveys',
                charts: 'survey_summary',
                survey_type: 'CSat',
                group_by: 'location'
            }
        }

        const tracer = useTracer.getState()

        tracer.trace('Analytics', 'Api', `GET ${url}`)

        try {
            const response = await httpClient.get(url, params);
            return this.deserialize(response.data);
        } catch (error) {
            tracer.trace('Analytics', 'Error', `${error}`)
            console.error('Error fetching survey summary:', error);
            throw error;
        } finally {
            tracer.trace('Analytics', 'Api', 'Success')
        }

    }

    deserialize(data) {

        const serializer = new SurveyLocationListSerializer()
        const summary = serializer.deserializeList(data.survey_summary)

        return summary
    }

}

