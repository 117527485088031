import React from "react";
import useAppStore from "../../../../../../../../appStore";

const BadgeStepIcon = ({ active, count }) => {

  const {whiteLabelProps} = useAppStore();

  return (
    <div style={{
      width: 27,
      height: 27,
      borderRadius: '50%',
      backgroundColor: active ? whiteLabelProps.primary_color : 'lightgray',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: 'white',
      cursor: 'pointer'
    }}>
      <b>{count}</b>
    </div>
  )


};

export default BadgeStepIcon;