import React from "react";
import { Box, Divider, useMediaQuery, useTheme } from "@mui/material";
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { AttachFile, Bookmark, Visibility } from "@mui/icons-material";
import MediaRowSquares
  from "../../../../../../shared/components/media/media-viewers/photos/MediaRowSquares";

const WorkItemReadOnly = (props) => {
  const { workItemToEdit, headers=true } = props;

  const isMobile = useMediaQuery(useTheme().breakpoints.only('xs'));

  const isGoodCatch = workItemToEdit.is_good_catch;
  const isOverdue = workItemToEdit.is_overdue;
  const timeOverdue = workItemToEdit.time_overdue;
  const timeRemaining = workItemToEdit.time_remaining;
  const isViewed = workItemToEdit.is_viewed;
  const notes = workItemToEdit.description;

  return (
    <div className="flex-column-fluid">
      <div style={{ marginBottom: "15px" }}>
        {headers &&
          <h3 className="card-title align-items-start flex-column">
            <span className="card-label fw-bold text-gray-700">Details</span>
          </h3>
        }
      </div>
      <div className="mb-3">
        <Box className="d-flex flex-column mt-8 w-100">
          <div className="d-flex flex-column">
            <div className="d-flex" style={{ width: "100px" }}>
              <div className="symbol symbol-35px me-4">
                        <span className="symbol-label bg-light-primary">
                                  <i className="fa-solid fa-bars-progress" style={{ color: "gray" }}></i>
                        </span>
              </div>
              <div className="d-flex justify-content-start flex-column">
                <span className="text-gray-400 fw-semibold d-block fs-7">Status</span>
                <a href="src/app/modules/work-items/components/edit-work-item/components/properties/Properties#"
                   className="text-gray-800 fw-bold text-hover-primary mb-1 fs-6">{workItemToEdit.status}</a>
              </div>
            </div>
            <div className="d-flex mt-5">
              <div className="symbol symbol-35px me-4">
                        <span className="symbol-label bg-light-danger">
                                    <AccessTimeIcon sx={{ color: "gray" }} />
                        </span>
              </div>
              <div className="d-flex justify-content-start flex-column">
                <span className="text-gray-400 fw-semibold d-block fs-7">Urgency</span>
                <a href="src/app/modules/work-items/components/edit-work-item/components/properties/Properties#"
                   className="text-gray-800 fw-bold text-hover-primary mb-1 fs-6">{isOverdue ? timeOverdue + " Overdue" : timeRemaining + " Remaining"}</a>
              </div>
            </div>
            <div className="d-flex mt-5">
              <div className="symbol symbol-35px me-4">
                        <span className="symbol-label bg-light-warning">
                                    <Bookmark sx={{ color: "gray" }} />
                        </span>
              </div>
              <div className="d-flex justify-content-start flex-column">
                <span className="text-gray-400 fw-semibold d-block fs-7">Marked Good Catch</span>
                <a href="src/app/modules/work-items/components/edit-work-item/components/properties/Properties#"
                   className="text-gray-800 fw-bold text-hover-primary mb-1 fs-6">{isGoodCatch ? "Yes" : "No"}</a>
              </div>
            </div>
            <div className="d-flex mt-5">
              <div className="symbol symbol-35px me-4">
                        <span className="symbol-label bg-light-success">
                                    <Visibility sx={{ color: "gray" }} />
                        </span>
              </div>
              <div className="d-flex justify-content-start flex-column">
                <span className="text-gray-400 fw-semibold d-block fs-7">Seen by Employee</span>
                <a href="src/app/modules/work-items/components/edit-work-item/components/properties/Properties#"
                   className="text-gray-800 fw-bold text-hover-primary mb-1 fs-6">{isViewed ? "Yes" : "No"}</a>
              </div>
            </div>
          </div>
        </Box>
      </div>

      <Divider sx={{ marginBottom: "15px", width: "100%" }} />

      <div className="mb-3">
        <span className="text-gray-400 fw-semibold d-block fs-7">Additional Notes:</span>
        <span className="fs-7">{notes || "No additional information"}</span>
      </div>

      <div className="d-flex mt-10">
        <AttachFile sx={{ color: "gray", fontSize: '45px' }} />
        <div className="d-flex justify-content-start flex-column">
          <MediaRowSquares itemMedia={workItemToEdit.media} captions={workItemToEdit.status === 'Closed'} />
        </div>
      </div>

    </div>
  );
};

export default WorkItemReadOnly;
