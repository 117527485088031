import { Box, useMediaQuery, useTheme } from "@mui/material";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import React from "react";
import { getTimeDifferenceString } from "../../../../../../../shared/utils/table-modifier-util/tableDataUtil";

const CaseProperties = (props) => {
  const { caseToEdit } = props;
  const isMobile = useMediaQuery(useTheme().breakpoints.only('xs'));

  const is_overdue = caseToEdit.is_overdue;
  const time_overdue = caseToEdit.time_overdue;
  const time_remaining = caseToEdit.time_remaining;
  const status = caseToEdit.status;


  return (
    <div className="mb-3">
      <Box className="d-flex flex-row mt-8 w-100">
        <div className="d-flex align-items-start" style={{ flexDirection: isMobile ? "column" : "row" }}>
          <div className="d-flex" style={{ marginBottom: isMobile ? '8px' : '', marginRight: isMobile ? '' : '25px' }}>
            <div className="symbol symbol-35px me-4">
                        <span className="symbol-label bg-light-success">
                                  <i className="fa-solid fa-folder" style={{ color: "gray" }}></i>
                        </span>
            </div>
            <div className="d-flex justify-content-start flex-column">
              <span className="text-gray-400 fw-semibold d-block fs-7">Case Type</span>
              <a href="src/app/modules/work-items/components/edit-work-item/components/properties/Properties#"
                 className="text-gray-800 fw-bold text-hover-primary mb-1 fs-6">{caseToEdit?.case_type?.name}</a>
            </div>
          </div>
          <div className="d-flex" style={{ marginBottom: isMobile ? '8px' : '', marginRight: isMobile ? '' : '25px' }}>
            <div className="symbol symbol-35px me-4">
                        <span className="symbol-label bg-light-danger">
                                    <AccessTimeIcon sx={{ color: "gray" }} />
                        </span>
            </div>
            <div className="d-flex justify-content-start flex-column">
              <span className="text-gray-400 fw-semibold d-block fs-7">Submitted</span>
              <a href="src/app/modules/work-items/components/edit-work-item/components/properties/Properties#"
                 className="text-gray-800 fw-bold text-hover-primary mb-1 fs-6">
                {getTimeDifferenceString(caseToEdit.create_date)}
              </a>
            </div>
          </div>
          <div className="d-flex" style={{ marginBottom: isMobile ? '8px' : '', marginRight: isMobile ? '' : '25px' }}>
            <div className="symbol symbol-35px me-4">
                        <span className="symbol-label bg-light-primary">
                                  <i className="fa-solid fa-bars-progress" style={{ color: "gray" }}></i>
                        </span>
            </div>
            <div className="d-flex justify-content-start flex-column">
              <span className="text-gray-400 fw-semibold d-block fs-7">Status</span>
              <a href="src/app/modules/work-items/components/edit-work-item/components/properties/Properties#"
                 className="text-gray-800 fw-bold text-hover-primary mb-1 fs-6">{status || "Created"}</a>
            </div>
          </div>
          <div className="d-flex" style={{ marginBottom: isMobile ? '8px' : '' }}>
            <div className="symbol symbol-35px me-4">
                        <span className="symbol-label bg-light-warning">
                                  <i className="fa-solid fa-pencil" style={{ color: "gray" }}></i>
                        </span>
            </div>
            <div className="d-flex justify-content-start flex-column">
              <span className="text-gray-400 fw-semibold d-block fs-7">Created By</span>
              <a href="src/app/modules/work-items/components/edit-work-item/components/properties/Properties#"
                 className="text-gray-800 fw-bold text-hover-primary mb-1 fs-6">{caseToEdit?.created_by.person.first_name + " " + caseToEdit?.created_by.person.last_name}</a>
            </div>
          </div>
        </div>
      </Box>
    </div>
  );
};

export default CaseProperties;

