import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { ErrorMessage } from "../../../../../../shared/components/page/error-message/ErrorMessage";
import {Editor} from "@tinymce/tinymce-react";
import CategorySelector from "./components/category-selector/CategorySelector";
import { ServicesService } from "../../../../../../shared/services/services/servicesService";
import { Service } from "../../../../../../shared/models/service.model";




const CourseForm = (props) => {

    const {
        formik,
        courseCategories
    } = props

    const [ trainingContent, setTrainingContent ] = React.useState('');

    const handleEditorChange = (e) =>{

        formik.setFieldValue('html_content', e)
        setTrainingContent(e)
    }

    const handleCategorySelected = (type, category) => {
        formik.setFieldValue('category_id', category.id)
    }



    return (

      <div className="flex-column-fluid">
          <form className="form form-label-right mb-4"
                onSubmit={formik.handleSubmit}
          >
              <div className="form-group row">
                  <div className="col-lg-12">
                      <div className="form-group">
                          <label className='required fw-bold fs-6 mb-2'>Name</label>
                          <input
                              placeholder=''
                              type='text'
                              name='name'
                              className={clsx(
                                  'form-control form-control-solid mb-3 mb-lg-0',
                                  //{'is-invalid': formik.touched.name && formik.errors.name},
                                  {
                                      //    'is-valid': formik.touched.name && !formik.errors.name,
                                  }
                              )}
                              autoComplete='off'
                              onChange={formik.handleChange}
                              value={formik.values.name}
                              // disabled={formik.isSubmitting || isUserLoading}
                          />
                      </div>
                  </div>
                  <div className="col-lg-12">
                      <div className="form-group mt-4">
                          <label className='fw-bold fs-6 mb-2 mt-3'>Description</label>
                            <textarea
                                name="description"
                                placeholder=""
                                className="form-control form-control-solid mb-3 mb-lg-0"
                                style={{height: '100px'}}
                                onChange={formik.handleChange}
                                value={formik.values.description}
                            />
                          <ErrorMessage name="description">
                              {(msg) => <span style={{ color: "red" }}>{msg}</span>}
                          </ErrorMessage>
                      </div>
                  </div>
                  <div className="col-lg-12">
                      <div className="form-group mt-4">
                          <label className='required fw-bold fs-6 mb-2 mt-3'>Category</label>
                          <div className="mb-3 mb-lg-0">

                              <CategorySelector courseCategories={courseCategories}
                                                onCategorySelected={handleCategorySelected}
                              />

                          </div>
                      </div>
                  </div>
                  <div className="col-lg-12">
                      <div className="form-group mt-6">
                          <label className='required fw-bold fs-6 mb-2'>Status</label>
                          <select
                              className="form-select form-control form-control-solid mb-3 mb-lg-0"
                              name="status"
                              placeholder="Select status"
                              onChange={formik.handleChange}
                              value={formik.values.status}
                          >
                              <option value='Draft'>Draft</option>
                              <option value='Active'>Active</option>
                              <option value='In Active'>In Active</option>
                          </select>
                      </div>
                  </div>
                  <div className="col-lg-12">
                      <div className="form-group mt-4">
                          <label className='required fw-bold fs-6 mb-2 mt-3'>Content</label>
                          <Editor
                              apiKey='nm8mugahxrzwkm3jxstv6loev85mdojg755thkmjhiir8n7r'
                              value={formik.values.html_content}
                              onEditorChange={(e) => handleEditorChange(e)}
                              cloudChannel='6-dev'
                              disabled={false}
                              inline={false}
                          />
                      </div>
                  </div>

              </div>
          </form>
      </div>
    )

}

export default CourseForm
