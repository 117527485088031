import React from "react";
import { FormControl, MenuItem, Select, TextField } from "@mui/material";
import useInspectionsStore from "../../../../../../inspectionsStore";
import SelectableTilesGrid from "./components/selectable-tiles/SelectableTilesGrid";
import { useEditInspectedEntity, ValidationResult } from "../../useEditInspectedEntity";
import CustomLoadingDots from "../../../../../../../../shared/components/page/popup-dialog/CustomLoadingDots";
import CustomError from "../../../../../../../../shared/components/page/popup-dialog/CustomError";
import { useEntityFetch } from "../../../../../../../../shared/custom-hooks/where-and-what/useEntityFetch";

const SpaceSelection = () => {

    const {biRef, isEditing } = useEditInspectedEntity();
    const { inspectedEntity, building, floor, setBuilding, setFloor, setSpaceType, setSpaceNumber } = useInspectionsStore()
    const { entities, entityTypes, floors, isLoading, floorsLoading, typesLoading, error } = useEntityFetch(building?.id, floor?.id, 0)

    if (isLoading) {
        return <CustomLoadingDots />;
    }
    if (error) {
        return <CustomError error={error} />;
    }

    const handleBuildingChanged = (event) => {
        const selectedBuildingName = event.target.value;
        const selectedBuilding = entities.find(bldg => bldg.name === selectedBuildingName);
        setBuilding(selectedBuilding ? selectedBuilding : null);
    };

    const handleFloorSelectionChanged = (event, selectedEntity) => {
        setFloor(selectedEntity);
    };

    const handleEntityTypeChanged = (event, spaceType) => {
        setSpaceType(spaceType);
    };

    const handleSpaceNumberChanged = (event) => {
        setSpaceNumber(event.target.value);
    };


    const validate = () => {

        let valInfo: ValidationResult = {passed: true, messages: []}

        if( building === null ){
            valInfo.passed = false
            valInfo.messages.push('Building is required.')
        }

        if( inspectedEntity.entity === null || inspectedEntity.entity.id === 0 ){
            valInfo.passed = false
            valInfo.messages.push('Floor is required.')
        }

        return valInfo
    }

    biRef.selectionPage.validate = validate;

    return (

      <div className="d-flex flex-column flex-row-fluid" style={{paddingBottom:"150px"}}>
          <h1>Select Space</h1>
              <>
              <div className='mt-10'>
                  <h3>What building are you inspecting today?</h3>
                  <FormControl style={{ width: '100%' }} margin="normal">
                      <Select
                        value={building ? building.name : ''}
                        onChange={handleBuildingChanged}
                        MenuProps={{
                            PaperProps: {
                                style: {
                                    width: '80%',
                                    height: '70%',
                                },
                            },
                        }}
                      >
                          {entities.map((bldg) => (
                            <MenuItem key={bldg.id} value={bldg.name}>
                                {bldg.name}
                            </MenuItem>
                          ))}
                      </Select>
                  </FormControl>
              </div>
                  {floors.length > 0 && building && (
                    <div className="mt-10">
                        <h3>What floor are you inspecting today?</h3>
                        {floorsLoading ? (
                          <div style={{ marginTop: "15px" }}>
                              <CustomLoadingDots />
                          </div>
                        ) : (
                          <SelectableTilesGrid disabled={isEditing}
                                               options={floors}
                                               value={inspectedEntity.entity}
                                               onChange={handleFloorSelectionChanged}
                                               sageModal={true}
                          />
                        )}
                    </div>
                  )}

                  <div className="mt-10" style={{ width: "100%" }}>
                      <h3>Select space type</h3>
                      {typesLoading ? (
                        <div style={{ marginTop: "15px" }}>
                            <CustomLoadingDots />
                        </div>
                      ) : (
                        <SelectableTilesGrid disabled={isEditing}
                                             options={entityTypes}
                                             value={inspectedEntity.entity_type}
                                             onChange={handleEntityTypeChanged}
                                             sageModal={true}
                        />
                      )}
              </div>
              <div className="mt-10">
                  <h3>Enter space / room number</h3>
                  <TextField placeholder='Room number'
                             variant="outlined"
                             onChange={handleSpaceNumberChanged}
                             value={inspectedEntity.space_number}
                  />
              </div>
              </>

      </div>

    )};

export default SpaceSelection;