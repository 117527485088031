import React, { useEffect, useState } from "react";
import { AppBar, Button, Dialog, IconButton, Typography } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import Toolbar from '@mui/material/Toolbar';
import useTracer from "../../../../shared/framework/tracer/tracer";
import EditInspectedEntity from "./components/edit-inspected-entity/EditInspectedEntity";
import useInspectionsStore from "../../inspectionsStore";
import { InspectionService } from "../../../../shared/services/inspections/inspectionService";
import InspectionSummary from "./components/inspection-summary/InspectionSummary";
import JointInspectionTile from "./components/joint-inspection/JointInspectionTile";
import ModalTransition from "../../../../shared/components/page/transitions/ModalTransition";
import InspectedEntitiesCardList from "./components/inspected-entities-list/mobile-view/InspectedEntitiesCardList";
import CustomLoadingDots from "../../../../shared/components/page/popup-dialog/CustomLoadingDots";
import { ConfirmationDialog } from "../../../../shared/components/page/popup-dialog/ConfirmationDialog";
import InspectionDatePicker from "./components/inspection-custom-date/InspectionDatePicker";
import useAppStore from "../../../../appStore";
import SageInspectModal from "./components/create-inspected-entity/AssistedInspectionModal";
import ModalFooterActions from "../../../../shared/components/buttons/ModalFooterActions";

const EditAreaInspectionPage = (props) => {
    const {
        onDelete,
        state,
        setState,
    } = props;

    const tracer = useTracer();

    const { inspection,
            setInspection,
            newInspectedEntity,
            setInspectedEntity,
            setBuilding,
            setFloor,
          } = useInspectionsStore();

    const {loggedInUser, toggleMasterRefresh, masterRefresh} = useAppStore()
    const dateChangePermission = loggedInUser?.permissions?.hasAccess( 'Change Inspection Date') || false;
    const [isAddInspectionOpen, setIsAddInspectionOpen] = useState(false);
    const [isEditInspectionOpen, setIsEditInspectionOpen] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [isNew, setIsNew] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
    const [totalSpaces, setTotalSpaces] = useState<number>(0);

    useEffect(() => {

        if (state.inspectionToEdit === null) {
            return;
        }
        const getInspectionDetails = async () => {
            setIsLoading(true);
            try {
                const service = new InspectionService();
                let newInspection = await service.getInspectionDetails(state.inspectionToEdit.id);
                setInspection(newInspection);
                newInspectedEntity();
            } catch (error) {
                tracer.trace('Inspections', 'Error', `Error: ${error}`);
                if (error instanceof Error) {
                    console.log(error.message);
                }
            } finally {
                setIsLoading(false);
            }
        };

        if (state.inspectionToEdit.id !== null) {
            tracer.trace('Inspections', 'Trace', 'Getting inspection details');
            setIsEditing(false);
            getInspectionDetails().then();
        } else {
            tracer.trace('Inspections', 'Trace', 'Creating new Inspection');
            setInspection(state.inspectionToEdit);
            newInspectedEntity();
            setIsEditing(true);
        }

    }, [state.inspectionToEdit]);

    const handleClose = () => {
        setIsAddInspectionOpen(false)
        setState(prevState => ({ ...prevState, open: false, inspectionToEdit: null }));
    }

    const toggleEditMode = () => {
        setIsEditing(!isEditing);
    }

    const handleSave = async () => {
        try {
            const service = new InspectionService();
            await service.updateInspection(inspection);
            setInspection(inspection);
            setIsAddInspectionOpen(false);
            console.log('Inspection updated successfully.');
        } catch (error) {
            console.error('Error saving inspection:', error);
        } finally {
            toggleMasterRefresh();
            handleClose()
        }
    };

    const handleInspectClicked = () => {
        newInspectedEntity()
        setIsNew(true)
        setBuilding(null)
        setFloor(null)
        setIsAddInspectionOpen(true)
    }

    const handleDeleteClicked = () => {
        setIsConfirmDialogOpen(true);
    };

    const handleConfirmDelete = async () => {
        handleClose();
        onDelete(state.inspectionToEdit);
    }

    const handleOnActionClicked = () => {
        setIsAddInspectionOpen(false)
    }

    const handleEditInspectedEntityClicked = (entity) => {
        setInspectedEntity(entity)
        setIsNew(false)
        setIsEditInspectionOpen(true)
    }

    if (isLoading) {
        return <CustomLoadingDots />;
    }

    return (
      <>
          <Dialog fullScreen open={state.open} onClose={handleClose} TransitionComponent={ModalTransition}
                  PaperProps={{
                      style: {
                          overflowX: 'hidden',
                          overflowY: 'auto',
                      },
                  }}>
              <AppBar sx={{ position: 'fixed', top: 0, left: 0, right: 0 }}>
                  <Toolbar>
                      <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                          <CloseIcon />
                      </IconButton>
                      <Typography sx={{ ml: 2, flex: 1 }} variant="h5" component="div">
                          {'Inspection Tool'}
                      </Typography>
                      {!isEditing && (
                        <Button
                          color="inherit"
                          variant="contained"
                          onClick={toggleEditMode}
                          sx={{ ml: 'auto', color: '#444444', mr: '3px' }}
                        >
                            {"Edit"}
                        </Button>
                      )}
                      {onDelete !== null && inspection.id !== 0 &&
                        <Button
                          color="inherit"
                          variant="contained"
                          disabled={totalSpaces === 0}
                          onClick={handleDeleteClicked}
                          sx={{
                              color: '#444444'
                          }}
                        >
                            {"Delete"}
                        </Button>
                      }
                  </Toolbar>
              </AppBar>


              <SageInspectModal open={isAddInspectionOpen} onActionClicked={handleOnActionClicked} />
              <EditInspectedEntity open={isEditInspectionOpen} onActionClicked={() => setIsEditInspectionOpen(false)} />


              <div className="d-flex flex-column flex-column-fluid mx-5"
                   style={{ height: "calc(100vh - 100px)", marginTop: '80px' }}>
                  <InspectionSummary totalSpaces={totalSpaces} setTotalSpaces={setTotalSpaces} />
                  <div style={{ marginBottom: dateChangePermission ? "5px" : "20px" }}>
                      <JointInspectionTile isEditing={isEditing} />
                  </div>
                  {dateChangePermission &&
                    <div style={{ marginBottom: "20px" }}>
                        <InspectionDatePicker isEditing={isEditing} />
                    </div>
                  }
                  <div style={{paddingBottom: '50px'}}>
                      <InspectedEntitiesCardList
                        onEditInspectedEntityClicked={handleEditInspectedEntityClicked}
                        onAddInspectionsClick={handleInspectClicked}
                        isEditing={isEditing}
                      />
                  </div>
              </div>
              <ConfirmationDialog
                isOpen={isConfirmDialogOpen}
                setIsOpen={setIsConfirmDialogOpen}
                message="Confirm Deletion"
                description="Are you sure you want to delete this inspection? This action cannot be undone."
                onConfirmClicked={handleConfirmDelete}
              />
              {isEditing &&
                <ModalFooterActions onClose={handleClose} onSave={handleSave} />
              }
          </Dialog>
      </>
    );
}

export default EditAreaInspectionPage
