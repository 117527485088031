import { CommandGetCharts } from "./commands/cmdGetCharts";
import { CommandGetSurveySummary } from "./commands/cmdGetSurveySummary";
import { CommandGetSurveyQuestionAverages } from "./commands/cmdGetSurveyQuestionAverages";
import { CommandGetRespondents } from "./commands/cmdGetSurveyRespondants";
import { CommandGetReportingGroupScores } from "./commands/cmdGetReportingGroupScores";
import { Organization } from "../../models/organization.model";
import { CommandGetLocationSummaries } from "./commands/cmdGetLocationSummaries";
import { LocationModel } from "../../models/location.model";


export class AnalyticsService {

    async getCharts(provider_id, location_id, module_name, charts, startDate, endDate) {
        const cmd = new CommandGetCharts()
        const results = cmd.run(provider_id, location_id, module_name, charts, startDate, endDate)
        return results
    }

    async getSurveySummary(organization: Organization, location?: LocationModel) {
        const cmd = new CommandGetSurveySummary()
        const summary = cmd.run(organization, location)
        return summary
    }

    async getLocationsSummaries(organization: Organization) {
        const cmd = new CommandGetLocationSummaries()
        const summary = cmd.run(organization)
        return summary
    }

    async getRespondents(organization, location, reportingGroupEntryId) {
        const cmd = new CommandGetRespondents()
        const respondents = cmd.run(organization, location, reportingGroupEntryId)
        return respondents
    }

    async getQuestions(organization, location, reportingGroupEntryId) {
        const cmd = new CommandGetSurveyQuestionAverages()
        const questions = cmd.run(organization, location, reportingGroupEntryId)
        return questions
    }

    async getReportingGroupScore(organization, location) {
        const cmd = new CommandGetReportingGroupScores()
        const scores = cmd.run(organization, location)
        return scores
    }








}