import React from "react";
import { Card, CardContent, useMediaQuery, useTheme } from "@mui/material";
import {
  getEmployee,
  getFloor, getEntityTypeName, getBuildingName, formatDate
} from "../../../../../../../../shared/utils/table-modifier-util/tableDataUtil";
import ActionButton from "../../../../../../../../shared/components/buttons/ActionButton";
import ProgressBar
  from "../../../../../../../analytics/components/site-health/components/tabs/corp-overview/components/card/components/ProgressBar";
import useWorkItemCard from "../../../../../../../dashboard/components/user-work-items/hooks/useWorkItemCard";
import { useWorkItem } from "../../../../../../context/workItemContext";
import CommentModal from "../../../../../../../dashboard/components/user-work-items/components/components/CommentModal";
import CloseWorkItemModal
  from "../../../../../../../dashboard/components/user-work-items/components/components/CloseWorkItemModal";
import MediaDisplay from "../../../../../../../../shared/components/media/media-viewers/photos/MediaDisplay";
import useAppStore from "../../../../../../../../appStore";

const WorkItemMobileCard = (props) => {
  const {
    workItem,
  } = props;


  const { loggedInUser, toggleMasterRefresh } = useAppStore();
  const { setWorkItemToEdit, setOpen } = useWorkItem();
  const { commentsOpen, setCommentsOpen, handleWorkItemEditorAction, closeOpen, handleCloseClicked, handleCloseWorkItem, value, colorClass, label } = useWorkItemCard(workItem, toggleMasterRefresh);
  const serviceItems = workItem.where_and_what?.[0]?.service_items || [];
  const isClosed = (workItem.status === "Closed");
  const isMobile = useMediaQuery(useTheme().breakpoints.only('xs'));

  const renderStatusSection = () => {
    return (
      <div className="d-flex flex-row align-items-center">
        <div className="d-flex flex-column"
             style={{ maxWidth: "120px", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", paddingLeft: "10px" }}>
        <span className="text-gray-800 fw-bold fs-7" style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
          {(workItem.assigned_to_employees?.length) ? getEmployee(workItem) : 'Unassigned'}
        </span>
          <span className="text-muted fs-7" style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
          {isClosed ? 'closed by' : 'assigned to'}
        </span>
        </div>
      </div>
    );
  };



  return (
    <><Card variant="outlined" sx={{
      position: "relative",
      marginTop: '20px',
      marginBottom: "20px",
      boxShadow: "0 1px 2px rgba(0,0,0,0.1)",
      height: "340px",
      border: isMobile ? '' : '1px solid #e5e5e5',
    }}>
      <CardContent style={{ position: "relative" }}>
        <div className="d-flex flex-row p-3" style={{
          position: "absolute",
          top: 0,
          left: 0,
          justifyContent: "space-between",
          width: "100%",
          borderBottom: "1px solid #e7e7e7",
          paddingBottom: "10px"
        }}>
          <div className="fs-7 d-flex flex-column" style={{ paddingLeft: "10px" }}>
            <span className="fw-bold">{getBuildingName(workItem)}</span>
            <span className="text-muted fst-italic">{getFloor(workItem)} / {getEntityTypeName(workItem)}</span>
          </div>
          <div className="d-flex align-items-center">
            <ActionButton
              iconType={"view"}
              onClick={(e) => {
                e.preventDefault();
                setWorkItemToEdit(workItem);
                setOpen(true);
              }}
            />
              <ActionButton iconType="comment" onClick={(e) => {
                e.preventDefault();
                setCommentsOpen(true);
              }} />
            {!isClosed &&
              <ActionButton iconType="done" onClick={(e) => {
                e.preventDefault();
                handleCloseClicked();
              }} />
            }
          </div>
        </div>

        <div style={{ display: "flex", flexDirection: "row", marginTop: "45px" }}>
          {/* COLUMN 1 MIDDLE */}
          <div style={{ width: "55%", paddingBottom: "10px", paddingLeft: "5px" }}>
            <div className="d-flex flex-column" style={{ maxWidth: "160px" }}>
              <div className="d-flex justify-content-start flex-column">
                <div className="d-flex justify-content-start flex-column">
                  <div className="d-flex flex-column text-primary fs-7" style={{ marginTop: "10px", height: "50px" }}>
                    {serviceItems && serviceItems.length > 0 ? (
                      serviceItems.slice(0, 3).map((item, index) => (
                        <div key={index} style={{
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          maxWidth: "185px",
                          fontSize: 11
                        }}>
                          {item.entity_type.name}
                        </div>
                      ))
                    ) : (
                      <div style={{ whiteSpace: "nowrap", maxWidth: "185px" }}>
                        No Services Specified
                      </div>
                    )}
                    {serviceItems && serviceItems.length > 3 &&
                      <div style={{
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        maxWidth: "185px"
                      }}>
                        +{serviceItems.length - 3} more
                      </div>}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* COLUMN 2 MIDDLE */}
          <div
            style={{ display: "flex", flexDirection: "column", width: "45%", marginTop: "10px", marginLeft: "10px" }}>
            <ProgressBar
              progress={isClosed ? 100 : value}
              label={isClosed ? "Completed" : "Urgency: " + label}
              colorClass={isClosed ? "bg-success" : colorClass}
              subtext={isClosed ? "" : workItem.is_overdue ? `${workItem.time_overdue} Overdue` : `${workItem.time_remaining} Remaining (Due: ${formatDate(workItem.due_date).slice(0, -5)})`} />
          </div>
        </div>
        <MediaDisplay itemMedia={workItem.media} captions={isClosed} module={'workitems'} />
      </CardContent>
      <div className="text-primary" style={{
        position: "absolute",
        bottom: 0,
        left: 0,
        width: "100%",
        fontSize: "0.75rem",
        color: "black",
        borderTop: "1px solid #e7e7e7"
      }}>
        <div className="d-flex flex-row p-3 text-gray-800"
             style={{ overflow: "hidden", display: "flex", flexDirection: "row" }}>
          <div style={{ width: "55%" }}>
            {renderStatusSection()}
          </div>
          <div className="d-flex flex-column" style={{ width: "45%", maxWidth: "145px", overflow: "hidden", textOverflow: "ellipsis" }}>
            <span className="text-gray-800 fw-bold fs-7">
                {workItem.created_by.person.first_name} {workItem.created_by.person.last_name}
            </span>
            <span className="text-muted fs-7">
              created on {formatDate(workItem.create_date)}
            </span>
          </div>
        </div>
      </div>
    </Card>
      <CommentModal workItem={workItem} setWorkItem={setWorkItemToEdit} open={commentsOpen}
                    onClose={() => setCommentsOpen(false)} />
      <CloseWorkItemModal open={closeOpen} onClose={handleWorkItemEditorAction}
                          handleCloseWorkItem={handleCloseWorkItem} />
    </>
  );
};

export default WorkItemMobileCard;
