import React, { useEffect, useState } from "react";
import {
  TextField,
  FormControl,
  Select,
  MenuItem
} from "@mui/material";
import useAppStore from "../../../../../../appStore";
import { CasesService } from "../../../../../../shared/services/cases/casesService";
import { GroupEntry } from "../../../../../../shared/models/groupEntry.model";
import { Media } from "../../../../../../shared/models/media.model";
import PhotoUploaderViewer from "../../../../../../shared/components/media/media-viewers/photos/PhotoUploaderViewer";
import InvolvedEmployees from "./employee-assignment/InvolvedEmployees";
import CaseAssignees from "./employee-assignment/CaseAssignees";
import SafetyCaseForm from "./custom-forms/SafetyCaseForm";

const CaseForm = (props) => {
  const {
    caseToEdit,
    setCaseToEdit,
    formErrors,
    setFormErrors,
    incident=false
  } = props;

  const { loggedInUser } = useAppStore();
  const service = new CasesService();
  const [departments, setDepartments] = useState<GroupEntry[]>([]);
  const [caseTypes, setCaseTypes] = useState<GroupEntry[]>([]);
  const [media, setMedia] = useState<Media[]>(caseToEdit.media);

  useEffect(() => {
    setCaseToEdit(prev => ({ ...prev, media: media }));
  }, [media]);

  useEffect(() => {
    const fetchDepartments = async () => {
      try {
        const depts = await service.getDepartments(loggedInUser?.organization);
        setDepartments(depts);
      } catch (error) {
        console.error('Error fetching departments:', error);
      }
    };

    const fetchTypes = async (dept) => {
      try {
        const types = await service.getCaseTypes(loggedInUser?.organization, dept);
        setCaseTypes(types);
      } catch (error) {
        console.error('Error fetching case types:', error);
      }
    };

    if (caseToEdit.department?.id && caseToEdit.department.id !== 0) {
      fetchTypes(caseToEdit.department.id).then();
    }

    fetchDepartments().then();
  }, [loggedInUser?.organization?.id, caseToEdit.department]);

  const handleDescChange = (event) => {
    setCaseToEdit(prev => ({ ...prev, description: event.target.value }));
  };

  const handleDepartmentChange = (event) => {
    const deptName = event.target.value;
    const selectedDepartment = departments.find(item => item.name === deptName);
    setCaseToEdit(prev => ({ ...prev, department: selectedDepartment, case_type: null }));
    setFormErrors(prevErrors => ({ ...prevErrors, department: !selectedDepartment }));
  };

  const handleCaseTypeChange = (event) => {
    const caseName = event.target.value;
    const selectedCase = caseTypes.find(item => item.name === caseName);
    setCaseToEdit(prev => ({ ...prev, case_type: selectedCase }));
    setFormErrors(prevErrors => ({ ...prevErrors, caseType: !selectedCase }));
  };

  return (
    <div className="flex-column-fluid" style={{ marginRight: '50px' }}>
      <div style={{ marginBottom: "15px" }}>
        <h3 className="card-title align-items-start flex-column">
          <span className="card-label fw-bold text-gray-700">{!incident ? 'Case Details' : 'Incident Details'}</span>
        </h3>
      </div>

      {!incident &&
        <>
          <FormControl fullWidth style={{ marginBottom: 15 }} error={formErrors.department}>
            <span className="text-gray-700">Department</span>
            <Select
              value={caseToEdit.department?.name || ""}
              onChange={handleDepartmentChange}
            >
              {departments.map((dept) => (
                <MenuItem key={dept.id} value={dept.name}>{dept.name}</MenuItem>
              ))}
            </Select>
            {formErrors.department && <span style={{ color: 'red' }}>Department is required</span>}
          </FormControl>

          <FormControl fullWidth style={{ marginBottom: 15 }} disabled={caseToEdit.department?.name === ''}
                       error={formErrors.caseType}>
            <span className={caseToEdit.department?.name === '' ? "text-muted" : "text-gray-700"}>Case Type</span>
            <Select
              value={caseToEdit.case_type?.name || ""}
              onChange={handleCaseTypeChange}
            >
              {caseTypes.map((type) => (
                <MenuItem key={type.id} value={type.name}>{type.name}</MenuItem>
              ))}
            </Select>
            {formErrors.caseType && <span style={{ color: 'red' }}>Case Type is required</span>}
          </FormControl>
        </>
      }

      {caseToEdit.case_type?.id === 9 &&
        <SafetyCaseForm caseToEdit={caseToEdit} setCaseToEdit={setCaseToEdit} />
      }

      <span className="text-gray-700">{caseToEdit.case_type?.id !== 9 ? 'Description' : 'Describe the incident in detail. What exactly occurred and what was said? Is it still ongoing?'}</span>
      <TextField
        name="Description"
        value={caseToEdit.description}
        onChange={handleDescChange}
        fullWidth
        multiline
        rows={5}
      />

      <div style={{ marginTop: 15 }}>
        <InvolvedEmployees caseToEdit={caseToEdit} setCaseToEdit={setCaseToEdit} />
      </div>

      <div style={{ marginTop: 15 }}>
        <CaseAssignees item={caseToEdit} setItem={setCaseToEdit} />
      </div>

      <div style={{ marginTop: 15 }}>
        <span className="text-gray-700">Supporting Documents</span>
        <PhotoUploaderViewer
          item={caseToEdit}
          setMedia={setMedia}
          captions={caseToEdit.status === 'Closed'}
          module={'cases'}
        />
      </div>

    </div>
  );
};

export default CaseForm;


