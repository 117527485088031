import httpClient from "../../../utils/http-clients/djangoHttpClient";
import { RespondentSerializer } from "../../../models/serializers/respondentSerializer";

export class CommandGetRespondents {

  SERVICE = process.env.REACT_APP_API_FACILITIES_SERVICE_URL;

  getUrl() {

    return  `${this.SERVICE}/analytics/`;
  }


  async run(organization, location, reportingGroupEntryId) {
    const url = this.getUrl();

    const params = {
      params: {
        organization: organization.id,
        location: location,
        module: 'surveys',
        charts: 'avg_scores_by_recipient',
        survey_type: 'CSat',
        reporting_group_entry: reportingGroupEntryId
      }
    }

    try {
      const response = await httpClient.get(url, params);
      return this.deserialize(response.data.avg_scores_by_recipient);
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    }
  }

  deserialize(data) {
    const serializer = new RespondentSerializer()
    const respondents = serializer.deserializeList(data)
    return respondents
  }

}