import React from 'react';
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import { Typography, useMediaQuery, useTheme } from "@mui/material";


const SurveySummaryTable = ({ data }) => {
  const isMobile = useMediaQuery(useTheme().breakpoints.only('xs'));

  return (
    <TableContainer component={Paper}>
      <Typography
        variant="h5"
        component="div"
        sx={{ padding: 2, textAlign: 'left', backgroundColor: '#f9f9f9', fontWeight: 'bold', color: '#444' }}
      >
        Locations
      </Typography>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell sx={{fontWeight: 'bold'}}>Name</TableCell>
            <TableCell align="center" sx={{fontWeight: 'bold'}}>Sent</TableCell>
            <TableCell align="center" sx={{fontWeight: 'bold'}}>Received</TableCell>
            <TableCell align="center" sx={{fontWeight: 'bold'}}>Average</TableCell>
            {!isMobile &&
              <TableCell align="center" sx={{fontWeight: 'bold'}}>Last Received</TableCell>
            }
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((summary) => (
            <TableRow key={summary.id}>
              <TableCell>{summary.name}</TableCell>
              <TableCell align="center">{summary.sent_count}</TableCell>
              <TableCell align="center">{summary.received_count}</TableCell>
              <TableCell align="center">{summary.avg_score !== null ? summary.avg_score : 'N/A'}</TableCell>
              {!isMobile &&
                <TableCell align="center">
                  {summary.last_received
                    ? new Date(summary.last_received).toLocaleString()
                    : 'N/A'}
                </TableCell>
              }
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default SurveySummaryTable;
