import React, {useState, useEffect} from "react";
import { useFormik } from 'formik';
import {Button} from "react-bootstrap";
import { useAuth } from "../../../../auth/core/Auth";
import CourseForm from "./components/CourseForm";
import { Course } from "../../../../../shared/models/course.model";
import { TrainingService } from "../../../../../shared/services/training/trainingService";
import ModalTransition from "../../../../../shared/components/page/transitions/ModalTransition";
import { AppBar, Dialog, IconButton, Toolbar, Typography } from "@mui/material";
import { CloseIcon } from "yet-another-react-lightbox";
import useTrainingStore from "../../trainingStore";


const EditCoursePage = (props) => {

    const {
        open,
        onClose,
        onSave,
        onActionClicked
    } = props

    const { trainingCategories, courseToEdit, setCourseToEdit } = useTrainingStore();

    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string>('');
    const buttonName = courseToEdit == null ? 'Create' : 'Update'

    const {currentUser} = useAuth();

    useEffect(() => {

        const fetchCourse = async () => {

            setIsLoading(true);

            try {

                const service = new TrainingService();
                const course = await service.getCourseDetails(courseToEdit?.id);

                setCourseToEdit(course);

                await formik.setValues({
                    name: course.name,
                    description: course.description,
                    status: course.status,
                    category_id: course.category_id,
                    html_content: course.html_content,
                });

            } catch (error) {
                if (error instanceof Error) {
                    setError(error.message);
                }
            }finally {
                setIsLoading(false);
            }
        };

        if (courseToEdit == null){
            setCourseToEdit(new Course())
        }else{
            fetchCourse().then();
        }


    }, []);

    const formik = useFormik({
        initialValues: {
            name: courseToEdit?.name,
            description: courseToEdit?.description,
            status: courseToEdit?.status,
            category_id: courseToEdit?.category_id,
            html_content: courseToEdit?.html_content,
        },
        onSubmit: async (values) => {
            /*
                if courseToUpdate is null, create a new Course
                populate the Course fields
                Get the logged in user. set created_by
                Call the Training Service to create the Course.
            */

            const newCourse = {...courseToEdit};


            const service = new TrainingService()

            if (courseToEdit == null ) {
                const course: Course = await service.createCourse(courseToEdit)
                onActionClicked('create', course)
            }else{
                const course: Course = await service.updateCourse(courseToEdit)
                onActionClicked('edit', course)
            }

        },
    });


    const createCourseHandler = () => {
        /*
            This is called when the user clicks Create.
            The call formik.submitForm() will result in the onSubmit being called above
            as part of useFormik.
         */

        formik.submitForm().then()

    }


    return (
      <Dialog fullScreen open={open} onClose={onClose} TransitionComponent={ModalTransition}>
          <AppBar sx={{ position: "relative" }}>
              <Toolbar>
                  <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close">
                      <CloseIcon />
                  </IconButton>
                  <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                      Edit Course
                  </Typography>
              </Toolbar>
          </AppBar>

        <div className='card'>
                <div className="card-body d-flex flex-row">

                    {/* Main Body Start */}
                    <div className="d-flex flex-column flex-row-fluid">

                           <CourseForm formik={formik}
                                       courseCategories={trainingCategories}
                           />

                    </div>
                    {/* Main Body End */}

                </div>

                {/* Buttons Start */}
                <div className="d-flex justify-content-end my-5 mx-10">
                    <Button variant="primary" className='btn btn-sm fw-bold me-3 mt-2' onClick={() => createCourseHandler()}>{buttonName}</Button>
                    {/*<Button variant="primary" className='btn btn-sm fw-bold me-3 mt-2' onClick={() => saveDraftHandler()}>Save as Draft</Button> */}
                    <Button variant="secondary" className='btn btn-sm fw-bold mt-2' onClick={() => onActionClicked('close')} >Cancel</Button>
                </div>
                {/* Buttons End */}
        </div>

      </Dialog>

    )

}

export default EditCoursePage
