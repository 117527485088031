import React, { useEffect, useState } from "react";
import CsatTile from "./csat-summary/CsatTile";
import JointInspectionTile from "./joint-inspection-summary/JointInspectionTile";
import useAppStore from "../../../../appStore";
import { AnalyticsService } from "../../../../shared/services/analytics/analyticsService";
import { SurveySummary } from "../../../../shared/models/surveySummary.model";
import CustomLoadingDots from "../../../../shared/components/page/popup-dialog/CustomLoadingDots";
import CustomError from "../../../../shared/components/page/popup-dialog/CustomError";
import { FormControl, MenuItem, Select, useTheme } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";

const ClientSummary = () => {
  const { loggedInUser, selectedLocation, whiteLabelProps } = useAppStore();
  const isMobile = useMediaQuery(useTheme().breakpoints.only('xs'));
  const service = new AnalyticsService();
  const [summary, setSummary] = useState<SurveySummary>(new SurveySummary());
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');
  const [timeframe, setTimeframe] = useState('1 year')
  const [selectedCampaign, setSelectedCampaign] = useState(1);

  useEffect(() => {
    const fetchCSAT = async () => {
      setIsLoading(true);
      setError('');
      try {
        const tileSummary = await service.getSurveySummary(
          loggedInUser.organization,
          //TODO: change to selected location
        );
        setSummary(tileSummary);
      } catch (error) {
        if (error instanceof Error) {
          setError(error.message);
        }
      } finally {
        setIsLoading(false);
      }
    };

    fetchCSAT().then();
  }, [loggedInUser, selectedLocation.id, timeframe]);

  const handleCampaignSelected = (event) => {
    setSelectedCampaign(event.target.value)
  };


  if (isLoading) {
    return (
      <CustomLoadingDots />
    );
  }

  if (error) {
    return (
      <CustomError error={error} />
    );
  }

  return (
    <>
      {/*<FormControl style={{ width: isMobile ? '100%' : '50%', marginBottom: '15px'}} >*/}
      {/*  <Select*/}
      {/*    value={selectedCampaign}*/}
      {/*    onChange={handleCampaignSelected}*/}
      {/*    MenuProps={{*/}
      {/*      PaperProps: {*/}
      {/*        style: {*/}
      {/*          maxHeight: 224,*/}
      {/*          backgroundColor: 'white'*/}
      {/*        },*/}
      {/*      },*/}
      {/*    }}*/}
      {/*  >*/}
      {/*      <MenuItem key={1} value={1}>*/}
      {/*        Campaign 1 (Not implemented)*/}
      {/*      </MenuItem>*/}
      {/*    <MenuItem key={2} value={2}>*/}
      {/*      Campaign 2 (Not implemented)*/}
      {/*    </MenuItem>*/}
      {/*    <MenuItem key={3} value={3}>*/}
      {/*      Campaign 3 (Not implemented)*/}
      {/*    </MenuItem>*/}
      {/*  </Select>*/}
      {/*</FormControl>*/}
      <CsatTile summary={summary}/>
      <JointInspectionTile />
    </>
);

}



export default ClientSummary;