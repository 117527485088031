import React from "react";
import { format } from "date-fns";

const IncidentReadOnly = (props) => {
  const { caseToEdit } = props;
  const formattedDate = caseToEdit?.form_data?.date_of_incident ? format(new Date(caseToEdit?.form_data?.date_of_incident), 'MMM d, yyyy') : '--';


  return (
    <>
      <div className="mb-3">
        <span className="text-gray-400 fw-semibold d-block fs-7">Injury / Illness Type:</span>
        <span className="fs-7">{caseToEdit?.form_data?.injury_illness_type || "--"}</span>
      </div>
      <div className="mb-3">
        <span className="text-gray-400 fw-semibold d-block fs-7">Date of Incident</span>
        <span className="fs-7">{formattedDate || "--"}</span>
      </div>
      <div className="mb-3">
        <span className="text-gray-400 fw-semibold d-block fs-7">Time of Incident</span>
        <span className="fs-7">{caseToEdit?.form_data?.time_of_incident || "--"}</span>
      </div>
      <div className="mb-3">
        <span className="text-gray-400 fw-semibold d-block fs-7">Incident Location</span>
        <span className="fs-7">{`${caseToEdit?.form_data?.incident_location || ' --'}`}</span>
      </div>
      <div className="mb-3">
        <span className="text-gray-400 fw-semibold d-block fs-7">Emergency / 911 Called?</span>
        <span className="fs-7">{caseToEdit?.form_data?.was_emergency || "--"}</span>
      </div>
      <div className="mb-3">
        <span className="text-gray-400 fw-semibold d-block fs-7">Triage Line Called?</span>
        <span className="fs-7">{caseToEdit?.form_data?.triage_line_called || "--"}</span>
      </div>
      <div className="mb-3">
        <span className="text-gray-400 fw-semibold d-block fs-7">Medical Treatment Recommended?</span>
        <span className="fs-7">{caseToEdit?.form_data?.medical_attention_recommended || "--"}</span>
      </div>
      <div className="mb-3">
        <span className="text-gray-400 fw-semibold d-block fs-7">Treatment steps given by nurse</span>
        <span className="fs-7">{caseToEdit?.form_data?.treatment_steps_given || "--"}</span>
      </div>
      <div className="mb-3">
        <span className="text-gray-400 fw-semibold d-block fs-7">Witnesses</span>
        <span className="fs-7">{caseToEdit?.form_data?.witnesses || "--"}</span>
      </div>
      <div className="mb-3">
        <span className="text-gray-400 fw-semibold d-block fs-7">Scene safe now?</span>
        <span className="fs-7">{caseToEdit?.form_data?.scene_safe || "--"}</span>
      </div>
    </>
  );
};

export default IncidentReadOnly;