import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import React, { useEffect, useState } from "react";
import IconButton from "@mui/material/IconButton";
import JointInspectionModal from "./modal/JointInspectionModal";
import { Grid } from "@mui/material";
import useAppStore from "../../../../../appStore";

const JointInspectionTile = () => {

  const [open, setOpen] = useState(false);
  const { appImages} = useAppStore();

  const handleOpenModal = () => {
    setOpen(true);
  };

  const handleCloseModal = () => {
    setOpen(false);
  }

  return (
      <Card sx={{
        height: "100%",
        boxShadow: "0 1px 2px rgba(0,0,0,0.1)",
        borderTopLeftRadius: '0', borderTopRightRadius: '0',
        border: '1px solid #dcdcdc', borderTop: 'none'
      }}>

        <CardContent>
          <Grid container alignItems="center" justifyContent="space-between">
            <Typography variant="h6" component="div"
                        sx={{ marginBottom: "25px", marginTop: "5px", fontWeight: "bold" }}>
              Joint Inspections
            </Typography>
            <IconButton onClick={handleOpenModal} sx={{ alignSelf: "flex-start" }}>
              <i className="fas fa-eye fs-1 me-1"></i>
            </IconButton>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={4} textAlign="center">
              <div style={{ height: "40px", alignItems: 'center', justifyContent: 'center' }}>
                <img src={appImages.iconPassed} style={{ width: "34px", height: "34px", marginTop: '1px' }} />
              </div>
              <Typography variant="h6" component="div" sx={{ fontWeight: "bold" }}>
                0.0
              </Typography>
              <Typography variant="body2" color="textSecondary">Average</Typography>
            </Grid>
            <Grid item xs={4} textAlign="center">
              <div style={{ height: "40px", alignItems: 'center', justifyContent: 'center' }}>
                <img src={appImages.iconContacts} style={{ width: "34px", height: "34px", marginTop: '2px' }} />
              </div>
              <Typography variant="h6" component="div" sx={{ fontWeight: "bold" }}>
                0
              </Typography>
              <Typography variant="body2" color="textSecondary">Contacts</Typography>
            </Grid>
            <Grid item xs={4} textAlign="center">
              <div style={{ height: "40px", alignItems: 'center', justifyContent: 'center' }}>
                <img src={appImages.iconInspectionAlt} style={{ width: "32px", height: "23px", marginTop: '9px' }} />
              </div>
              <Typography variant="h6" component="div" sx={{ fontWeight: "bold" }}>
                N/A
              </Typography>
              <Typography variant="body2" color="textSecondary">Last Inspection</Typography>
            </Grid>
          </Grid>

        </CardContent>
        {open && (
          <JointInspectionModal
            open={open}
            onActionClicked={handleCloseModal} />
        )}
      </Card>
  );

}



export default JointInspectionTile;