import React, { useEffect, useRef, useState } from "react";
import SagePrompter from "./SagePrompter";
import { Box, Button, useTheme } from "@mui/material";
import { Case } from "../../shared/models/case.model";
import AssistedCaseCreation from "./case-creation-assist/AssistedCaseCreation";
import { CasesService } from "../../shared/services/cases/casesService";
import useMediaQuery from "@mui/material/useMediaQuery";
import UserItemsList from "./created-items-assist/UserItemsList";
import { vaButtonContainerStyles, vaContainerStyles } from "../../shared/utils/table-modifier-util/tableDataUtil";
import useAppStore from "../../appStore";

const SageAssistantModal = ({ onClose, fullScreen = false, incident=false }) => {
  const [sageResponse, setSageResponse] = useState<string>("");
  const [caseToEdit, setCaseToEdit] = useState<Case>(new Case());
  const [isTyping, setIsTyping] = useState(true);
  const [step, setStep] = useState(0);
  const isMobile = useMediaQuery(useTheme().breakpoints.only('xs'));
  const modalRef = useRef<any>(null);
  const {appImages, whiteLabelProps} = useAppStore();

  useEffect(() => {
    const fetchType = async () => {
      try {
        const service = new CasesService();
        const safetyCaseType = await service.lookupCaseType(1, "safety_incident");
        setCaseToEdit(prev => ({ ...prev, case_type: safetyCaseType[0] }));
        setCaseToEdit(prev => ({ ...prev, department: safetyCaseType[0]?.department }));
        setStep(2)
      } catch (error) {
        console.error('Failed to fetch case type:', error);
      } finally {

      }
    }

    if (incident) {
      fetchType().then();
    }

  }, [incident]);

  useEffect(() => {
    const getResponseMessage = () => {
      const messages = {
        tell: [
          `Hey there! I'm ${whiteLabelProps.mascot_name}, your virtual assistant. What is this related to?`,
          `Ok let me start a ${caseToEdit?.department?.name} ticket for you. Can you give me a little more info?`,
          "Who are the involved employees?",
          "Would you like to provide any more details?",
          "Are there any supporting documents you would like to attach?",
          "Nice! here's what I have, everything look okay?",
          "Okay I submitted your ticket, is there anything else I can help you with today?",
          "Okay, here are your items!"
        ]
      };

      return messages["tell"][step] || "";
    };

    setSageResponse(getResponseMessage());
    if (step === -1) {
      setSageResponse("Here is a list of all items you have created. Try clicking one for details!")
    }
  }, [step]);

  const handleNextStep = () => setStep(step + 1);

  const handleSubmit = async () => {
    try {
      const service = new CasesService();
      await service.createCase(caseToEdit);
    } catch (e) {
      console.error('Error in handleSubmit:', e);
    }
    handleNextStep();
  };

  const handleRestart = () => {
    setCaseToEdit(new Case());
    setStep(0);
  };

  const handleShowItems = () => {
    setStep(-1);
  };

  return (
    <div style={vaContainerStyles(isMobile, fullScreen)} ref={modalRef}>
      <div style={{ overflowY: 'auto', flex: 1 }}>
        <SagePrompter sageResponse={sageResponse} setIsTyping={setIsTyping} />
        {!isTyping && (
          <Box sx={{ justifyContent: 'center', marginTop: '10px', marginBottom: '200px' }}>
            <AssistedCaseCreation
              caseToEdit={caseToEdit}
              setCaseToEdit={setCaseToEdit}
              step={step}
              setStep={setStep}
              setIsTyping={setIsTyping}
            />
            {step === 0 && (
              <>
              <div style={vaButtonContainerStyles(step)}>
                <Button
                  onClick={() => handleShowItems()}
                  style={{ marginBottom: '20px', fontSize: '1.1rem', color: '#999' }}
                >
                  <img src={appImages.iconPencilPaper} alt="Created Items"
                       style={{ width: 29, height: 32, marginRight: 8 }} />
                  MY CREATED ITEMS
                </Button>
                  </div>
              </>
              )}

            {step === -1 &&
              <>
                <UserItemsList setStep={setStep} />
                <div style={vaButtonContainerStyles(step)}>
                  <Button
                    onClick={() => setStep(0)}
                    style={{ marginBottom: "20px", fontSize: "1.1rem" }}
                  >
                    BACK
                  </Button>
                </div>
              </>
            }
          </Box>
        )}
      </div>
      <div style={vaButtonContainerStyles(step)}>
        {step !== -1 && (
          <>
            {step > 0 && step < 5 && (
              <Button
                onClick={() => setStep(step - 1)}
                style={{ marginBottom: "20px", marginRight: "20px", fontSize: "1.2rem" }}
              >
                Prev
              </Button>
            )}
            {step < 5 && step > 1 && (
              <Button
                onClick={handleNextStep}
                style={{ marginBottom: '20px', fontSize: '1.2rem' }}
              >
                Next
              </Button>
            )}
            {!isTyping && step === 5 && (
              <Button
                onClick={handleSubmit}
                style={{ marginBottom: '20px', fontSize: '1.2rem' }}
              >
                Submit
              </Button>
            )}
            {!isTyping && step === 6 && (
              <>

                <Button
                  onClick={handleRestart}
                  style={{ marginBottom: '20px', marginRight: '20px', fontSize: '1.2rem' }}
                >
                  YES
                </Button>
                <Button
                  onClick={onClose}
                  style={{ marginBottom: '20px', fontSize: '1.2rem' }}
                >
                  NO
                </Button>
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default SageAssistantModal;


