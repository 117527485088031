import React, { useEffect, useState } from "react";
import PageHeader from "../../../../shared/components/page/page-header/PageHeader";
import { SurveySummary } from "../../../../shared/models/surveySummary.model";
import SurveySummaryTiles from "./components/SurveySummaryTiles";
import TimeframeSelector from "./components/TimeframeSelector";
import { AnalyticsService } from "../../../../shared/services/analytics/analyticsService";
import useAppStore from "../../../../appStore";
import TierDualView from "./components/TierDualView";
import { ReportingGroupScore } from "../../../../shared/models/reportingGroupScore.model";
import CustomLoadingDots from "../../../../shared/components/page/popup-dialog/CustomLoadingDots";
import CustomError from "../../../../shared/components/page/popup-dialog/CustomError";
import { Tab, Tabs } from "@mui/material";
import SurveySummaryTable from "./components/SurveySummaryTable";

const SurveyAnalyticsPage = () => {
  const { loggedInUser, selectedLocation } = useAppStore();
  const service = new AnalyticsService();
  const [summary, setSummary] = useState({});
  const [groupedSummaries, setGroupedSummaries] = React.useState<SurveySummary[]>([]);
  const [reportingGroups, setReportingGroups] = React.useState<ReportingGroupScore[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');
  const [activeTab, setActiveTab] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      setError('');
      try {
        const tileSummary = await service.getSurveySummary(loggedInUser.organization, activeTab === 1 ? selectedLocation : undefined);
        const tierData = await service.getReportingGroupScore(
          loggedInUser.organization,
          selectedLocation.id
        );
        const groupedSummary = await service.getLocationsSummaries(loggedInUser.organization);
        setReportingGroups(tierData);
        setSummary(tileSummary);
        setGroupedSummaries(groupedSummary);
      } catch (error) {
        if (error instanceof Error) {
          setError(error.message);
        }
      } finally {
        setIsLoading(false);
      }
    };

    if (selectedLocation.id !== 0) {
      fetchData();
    }
  }, [loggedInUser, selectedLocation.id, activeTab]);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  if (isLoading) {
    return <CustomLoadingDots />;
  }

  if (error) {
    return <CustomError error={error} />;
  }

  return (
    <>
      <PageHeader title="Customer Surveys" showAction={false} divider={false} />
      <div style={{ marginBottom: '10px' }}>
        <Tabs value={activeTab} onChange={handleTabChange}>
          <Tab label="Campaign Overview" />
          <Tab label="Location Scores" />
        </Tabs>
      </div>
      <SurveySummaryTiles summary={summary} />
      {activeTab === 0 && <SurveySummaryTable data={groupedSummaries} />}
      {activeTab === 1 && <TierDualView data={reportingGroups} />}
    </>
  );
};

export default SurveyAnalyticsPage;
