import { Suspense, useEffect, useState } from "react";
import { Outlet } from 'react-router-dom';
import { I18nProvider } from '../_metronic/i18n/i18nProvider';
import { LayoutProvider, LayoutSplashScreen } from '../_metronic/layout/core';
import { MasterInit } from '../_metronic/layout/MasterInit';
import { AuthInit, useAuth } from "./modules/auth";
import { ThemeModeProvider } from '../_metronic/partials';
import { createTheme, ThemeProvider } from '@mui/material';
import useAppStore from "./appStore";
import { WorkItemProvider } from "./modules/work-items/context/workItemContext";
import { SageAlert } from "./shared/components/page/popup-dialog/SageAlert";
import Hotjar from "./shared/components/hotjar/Hotjar";

const App = () => {
  const { whiteLabelProps } = useAppStore();
  const [notificationsOpen, setNotificationsOpen] = useState(false);

  const theme = createTheme({
    palette: {
      primary: {
        main: whiteLabelProps.primary_color,
        contrastText: '#fff',
      },
      secondary: {
        main: '#f1f1f2',
        contrastText: '#000',
      },
    },
    components: {
      MuiButton: {
        styleOverrides: {
          containedPrimary: {
            height: '40px',
            color: 'white',
          },
        },
      },
    },
    typography: {
      button: {
        fontWeight: 'bold',
      },
    },
  });

  const handleCloseNotifications = () => {
    setNotificationsOpen(false);
  };

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <I18nProvider>
        <LayoutProvider>
          <ThemeProvider theme={theme}>
            <ThemeModeProvider>
              <AuthInit>
                <Hotjar />
                <SageAlert open={notificationsOpen} onClose={handleCloseNotifications} />
                <WorkItemProvider>
                  <Outlet />
                  <MasterInit />
                </WorkItemProvider>
              </AuthInit>
            </ThemeModeProvider>
          </ThemeProvider>
        </LayoutProvider>
      </I18nProvider>
    </Suspense>
  );
}

export { App };
