import React, { useEffect } from "react";
import PageHeader from "../../shared/components/page/page-header/PageHeader";
import EmployeeList from "./components/employee-list/EmployeeList";
import { Employee } from "../../shared/models/employee.model";
import useAppStore from "../../appStore";
import EditEmployee from "./components/edit-employee/EditEmployee";
import { Tabs, Tab } from "@mui/material";

const EmployeesPage = () => {
  const { loggedInUser, toggleMasterRefresh } = useAppStore();
  const [employeeToEdit, setEmployeeToEdit] = React.useState<Employee | null>(null);
  const [open, setOpen] = React.useState(false);
  const canEdit = loggedInUser?.permissions.hasAccess("Employees", 15);

  const handleNewEmployee = () => {
    let newEmployee = new Employee();
    setEmployeeToEdit(newEmployee);
    setOpen(true);
  };

  const handlerCloseEditModal = (action) => {
    setOpen(false);
    setEmployeeToEdit(null);
    if (action === 'save') {
      toggleMasterRefresh();
    }
  };

  return (
    <>
      <PageHeader
        title="Employees"
        actionButtonName="New Employee"
        onActionClicked={handleNewEmployee}
        showAction={canEdit}
      />

      <EmployeeList />

      {open && (
        <EditEmployee
          isEdit={false}
          open={open}
          employee_id={employeeToEdit?.id}
          onActionClicked={handlerCloseEditModal}
        />
      )}
    </>
  );
};

export default EmployeesPage;

