import React, { useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { DateTime } from 'luxon';
import WorkItemForm from "./components/details/WorkItemForm";
import CommentsSection from "../../../../shared/components/comments/CommentsSection";
import AssignedTo from "./components/assigned-to/AssignedTo";
import { AppBar, Button, Dialog, IconButton, Toolbar } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ModalTransition from "../../../../shared/components/page/transitions/ModalTransition";
import { WorkItemsService } from "../../../../shared/services/work-items/workItemsService";
import useAppStore from "../../../../appStore";
import CustomError from "../../../../shared/components/page/popup-dialog/CustomError";
import { useWorkItem } from "../../context/workItemContext";
import WhereAndWhat from "./components/where-and-what/WhereAndWhat";
import WorkItemReadOnly from "./components/details/WorkItemReadOnly";
import { WorkItem } from "../../../../shared/models/workItem.model";
import { WorkItemPrefillModel } from "../../../../shared/models/workItemPrefill.model";
import { InspectedEntity } from "../../../../shared/models/inspectedEntity.model";
import ModalFooterActions from "../../../../shared/components/buttons/ModalFooterActions";

interface EditWorkItemProps {
    prefill?: WorkItem | null;
    inspectedEntity?: InspectedEntity;
    open: boolean;
    onActionClicked: (action: string) => void;
}

const EditWorkItem: React.FC<EditWorkItemProps> = ({ prefill = null, inspectedEntity = null, open, onActionClicked }) => {
    const { workItemToEdit, setWorkItemToEdit, setSortBy } = useWorkItem()
    const { loggedInUser, selectedLocation, toggleMasterRefresh } = useAppStore();
    const [newComments, setNewComments] = useState([]);
    const [error, setError] = useState('');
    const [isEditing, setIsEditing] = useState(workItemToEdit.id === 0);
    const [generatedPrefill, setGeneratedPrefill] = useState<WorkItemPrefillModel>(new WorkItemPrefillModel());

    const initializeWorkItem = () => {
        setWorkItemToEdit(prevWorkItem => ({
            ...prevWorkItem,
            due_date: DateTime.now().toUTC().plus({ days: 1 }).toISO(),
            created_by: loggedInUser.employee,
            location: {
                ...prevWorkItem.location,
                id: selectedLocation?.id || loggedInUser?.employee?.works_at?.[0]?.location?.id || 0,
            },
            provider: {
                ...prevWorkItem.provider,
                id: loggedInUser.organization?.id || 0,
            },
        }));
    };

    useEffect(() => {
        if (workItemToEdit.title === "" && workItemToEdit.description === "") {
            setWorkItemToEdit(prevWorkItem => {
                const updatedWorkItem = {
                    ...prevWorkItem,
                    title: generatedPrefill?.work_item?.title || prevWorkItem.title,
                    description: generatedPrefill?.work_item?.description || prevWorkItem.description,
                };

                return updatedWorkItem;
            });
        }
    }, [generatedPrefill]);


    useEffect(() => {
        if (!workItemToEdit.id) {
            initializeWorkItem();
        } else {
            const fetchWorkItemDetails = async () => {
                try {
                    const service = new WorkItemsService();
                    const workItem = await service.getWorkItemDetails(workItemToEdit.id);
                    setWorkItemToEdit(workItem);
                } catch (error) {
                    console.error('Failed to fetch work item details:', error);
                }
            };
            fetchWorkItemDetails().then();
        }
    }, [workItemToEdit.id]);

    useEffect(() => {
        if (prefill) {
            setWorkItemToEdit(prevWorkItem => ({
                ...prevWorkItem,
                title: prefill?.title || prevWorkItem.title,
                description: prefill?.description || prevWorkItem.description,
                where_and_what: prefill?.where_and_what || prevWorkItem.where_and_what,
                media: prefill?.media || prevWorkItem.media,
                source: inspectedEntity ? { type: 'Inspection', inspected_entity: inspectedEntity } : null,
            }));
        }
    }, [prefill]);

    const createPrefill = async (workItem) => {
        try {
            let source = new WorkItemPrefillModel();
            source.work_item = workItem;
            const response = await new WorkItemsService().createWorkItemPrefill(source);
            setGeneratedPrefill(response)
        } catch (e) {
            console.error('Error in prefill generation:', e);
        }
    };

    const handleSave = async () => {
        try {
            const service = new WorkItemsService();
            const isCreating = workItemToEdit.id === 0;
            const response = isCreating
              ? await service.createWorkItem(workItemToEdit)
              : await service.updateWorkItem(workItemToEdit);

            if (isCreating) setSortBy("create_date");
            const statusToStepMap = { 'Pending': '0', 'Closed': '2' };
            localStorage.setItem("activeStep", statusToStepMap[response.status] || '1');

            await postComments(service, response.id, newComments);
            setNewComments([]);
            onActionClicked('close');
            toggleMasterRefresh();
        } catch (e) {
            console.error('Error in handleSave:', e);
            setError(`Failed to update work item`);
        }
    };

    const postComments = async (service, workItemId, comments) => {
        for (const comment of comments) {
            await service.postComment(workItemId, comment);
        }
    };

    const renderCard = (Component, props = {}) => (
      <Card style={{ boxShadow: '0 1px 2px rgba(0,0,0,0.1)', margin: '5px', marginBottom: '10px', borderRadius: '0px' }}>
          <Card.Body>
              <Component {...props} />
          </Card.Body>
      </Card>
    );

    if (error) return <CustomError error={error} />;

    return (
      <Dialog
        fullScreen
        open={open}
        onClose={() => onActionClicked('close')}
        TransitionComponent={ModalTransition}
        PaperProps={{
            style: {
                overflowX: 'hidden',
                backgroundColor: '#e9e9e9',
                overflowY: 'auto',
            },
        }}
      >
          <AppBar sx={{ position: 'fixed', top: 0, left: 0, right: 0 }}>
              <Toolbar>
                  <IconButton edge="start" color="inherit" onClick={() => onActionClicked('close')} aria-label="close">
                      <CloseIcon />
                  </IconButton>
                  {!isEditing && (
                    <Button color="inherit" variant="contained" onClick={() => setIsEditing(true)} sx={{ ml: 'auto', color: '#444444' }}>
                        Edit
                    </Button>
                  )}
              </Toolbar>
          </AppBar>

          <Row className="edit-work-item-page mt-20">
              <Col xs={12} md={12}>
                  {renderCard(WhereAndWhat, { fromInspection: inspectedEntity, isEditing, createPrefill })}
                  {isEditing ? (
                    <>
                        {renderCard(WorkItemForm, { workItemToEdit, setWorkItemToEdit, isEditing })}
                        {renderCard(AssignedTo, { workItemToEdit, setWorkItemToEdit, isEditing })}
                        {renderCard(CommentsSection, { item: workItemToEdit, newComments, setNewComments, type: 'workitems' })}
                    </>
                  ) : (
                    <>
                        {renderCard(WorkItemReadOnly, { workItemToEdit })}
                        {renderCard(AssignedTo, { workItemToEdit, setWorkItemToEdit, isEditing })}
                        {renderCard(CommentsSection, { item: workItemToEdit, newComments, setNewComments, type: 'workitems' })}
                    </>
                  )}
              </Col>
          </Row>

          {isEditing && (
            <ModalFooterActions onClose={() => onActionClicked('close')} onSave={handleSave} text={workItemToEdit.id === 0 ? 'Submit' : 'Save'} />
          )}

      </Dialog>
    );
};

export default EditWorkItem;

