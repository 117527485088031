import React, { useState } from "react";
import { Divider, Typography, Button, Box } from "@mui/material";
import { AttachFile } from "@mui/icons-material";
import MediaRowSquares from "../../../../../../../shared/components/media/media-viewers/photos/MediaRowSquares";
import IncidentReadOnly from "./IncidentReadOnly";
import EmptyCard from "../../../../../../../shared/components/page/tables/EmptyCard";
import UserCard from "../../../../../../../shared/components/page/tiles/UserCard";
import CaseProperties from "./CaseProperties";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import UserCardSmall from "../../../../../../../shared/components/page/tiles/UserCardSmall";

const CaseReadOnly = (props) => {
  const { caseToEdit, headers = true } = props;
  const [isRelatedToExpanded, setIsRelatedToExpanded] = useState(false);
  const [isAssignedToExpanded, setIsAssignedToExpanded] = useState(false);

  const relatedToEmployees = caseToEdit.related_to_employees || [];
  const assignedToEmployees = caseToEdit.assigned_to_employees || [];

  return (
    <div className="flex-column-fluid me-10">
      <div style={{ marginBottom: "15px" }}>
        {headers && (
          <h3 className="card-title align-items-start flex-column">
            <span className="card-label fw-bold text-gray-700">
              {caseToEdit?.department?.name} Case (#{caseToEdit.case_number})
            </span>
          </h3>
        )}
      </div>

      <CaseProperties caseToEdit={caseToEdit} />

      <Divider sx={{ marginBottom: "15px", width: "100%" }} />

      <div className="mt-3 mb-5 flex-column" style={{width: '320px'}}>
        <Typography
          variant="subtitle1"
          className="text-gray-400 fw-semibold d-block fs-7"
        >
          Description:
        </Typography>
        <div className='card' style={{ width: '100%' }}>
          <div className='card-body d-flex flex-center flex-column p-4'>
            <Typography variant="body1">
              {caseToEdit.description || "--"}
            </Typography>
          </div>
        </div>

      </div>

      <div className="d-flex flex-row">
        {/* Related To */}
        <div
          className="mb-3 d-flex flex-column"
          style={{ marginRight: "15px" }}
        >
          <Typography
            variant="subtitle1"
            className="text-gray-400 fw-semibold d-block fs-7"
          >
            Involved Employees:
          </Typography>
          {relatedToEmployees.length > 0 ? (
            <>
              <UserCard employee={relatedToEmployees[0]} />
              {relatedToEmployees.length > 1 && (
                <>
                  <Button
                    variant="text"
                    size="small"
                    onClick={() =>
                      setIsRelatedToExpanded(!isRelatedToExpanded)
                    }
                    endIcon={
                      isRelatedToExpanded ? (
                        <ExpandLessIcon />
                      ) : (
                        <ExpandMoreIcon />
                      )
                    }
                    sx={{ mt: 1 }}
                  >
                    +{relatedToEmployees.length - 1}
                  </Button>
                  {isRelatedToExpanded && (
                    <Box sx={{ mt: 2, display: 'flex', flexDirection: 'column', maxWidth: '150px' }}>
                      {relatedToEmployees.slice(1).map((employee, index) => (
                        <UserCardSmall
                          key={employee.id || index}
                          name={`${employee?.person?.first_name || ""} ${
                            employee?.person?.last_name || ""
                          }`}
                          phone_number={employee?.person?.phone_number}
                        />
                      ))}
                    </Box>
                  )}
                </>
              )}
            </>
          ) : (
            <EmptyCard />
          )}
        </div>

        {/* Assigned To */}
        <div className="mb-3 d-flex flex-column">
          <Typography
            variant="subtitle1"
            className="text-gray-400 fw-semibold d-block fs-7"
          >
            Assigned To:
          </Typography>
          {assignedToEmployees.length > 0 ? (
            <>
              <UserCard employee={assignedToEmployees[0]} />
              {assignedToEmployees.length > 1 && (
                <>
                  <Button
                    variant="text"
                    size="small"
                    onClick={() =>
                      setIsAssignedToExpanded(!isAssignedToExpanded)
                    }
                    endIcon={
                      isAssignedToExpanded ? (
                        <ExpandLessIcon />
                      ) : (
                        <ExpandMoreIcon />
                      )
                    }
                    sx={{ mt: 1 }}
                  >
                    +{assignedToEmployees.length - 1}
                  </Button>
                  {isAssignedToExpanded && (
                    <Box sx={{ mt: 2, display: 'flex', flexDirection: 'column', maxWidth: '150px' }}>
                      {assignedToEmployees.slice(1).map((employee, index) => (
                        <UserCardSmall
                          key={employee.id || index}
                          name={`${employee?.person?.first_name || ""} ${
                            employee?.person?.last_name || ""
                          }`}
                          phone_number={employee?.person?.phone_number}
                        />
                      ))}
                    </Box>
                  )}
                </>
              )}
            </>
          ) : (
            <EmptyCard />
          )}
        </div>
      </div>

      {caseToEdit?.case_type?.id === 9 && (
        <IncidentReadOnly caseToEdit={caseToEdit} />
      )}

      <div className="d-flex flex-column align-items-start justify-content-start mt-5">
        <Typography
          variant="subtitle1"
          className="text-gray-400 fw-semibold d-block fs-7"
        >
          Attachments:
        </Typography>
        <div className="d-flex justify-content-start flex-column">
          <MediaRowSquares
            itemMedia={caseToEdit.media}
            captions={caseToEdit.status === "Closed"}
            size={97}
          />
        </div>
      </div>
    </div>
  );
};

export default CaseReadOnly;

