import React, { useState } from "react";
import PageHeader from "../../shared/components/page/page-header/PageHeader";
import WorkItemList from './components/work-item-list/WorkItemList';
import EditWorkItem from './components/edit-work-item/EditWorkItem';
import { WorkItem } from "../../shared/models/workItem.model";
import { WorkItemsService } from "../../shared/services/work-items/workItemsService";
import { ConfirmationDialog } from "../../shared/components/page/popup-dialog/ConfirmationDialog";
import { Box, Snackbar, useMediaQuery, useTheme } from "@mui/material";
import { useWorkItem } from "./context/workItemContext";
import useAppStore from "../../appStore";


const WorkItemsPage = () => {

    const workItemService = new WorkItemsService();

    const { toggleMasterRefresh } = useAppStore();
    const {
        workItemToEdit, setWorkItemToEdit,
        isEditing, setEditing,
        open, setOpen,
        snackbarOpen, setSnackbarOpen
        } = useWorkItem();

    const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
    const [workItemToDelete, setWorkItemToDelete] = useState<WorkItem | null>(null);

    const handleNewWorkItem = (e) => {
        let workItem = new WorkItem();
        setWorkItemToEdit(workItem)
        setOpen(true)
        setEditing(true)
    }
    const handleEditWorkItem = (workItem) => {
        setWorkItemToEdit(workItem);
        setEditing(true);
        setOpen(true);
    };

    const handleConfirmDelete = async () => {
        if (workItemToDelete) {
            try {
                await workItemService.deleteWorkItem(workItemToDelete.id);
                setConfirmDeleteOpen(false);
                toggleMasterRefresh();
            } catch (error) {
                console.error(error);
            }
        }
    };

    const handleDeleteWorkItemClicked = (workItem) => {
        setWorkItemToDelete(workItem);
        setConfirmDeleteOpen(true);
    };

    const handlerWorkItemEditorAction = (action) => {
        setEditing(false);
        setOpen(false);
        setWorkItemToEdit(new WorkItem());
        if (action === 'save') {
            setSnackbarOpen(true);
        }
    }

    return (
      <>
          {!isEditing && (
            <div>
                <PageHeader
                  title='Work Items'
                  actionButtonName='New Work Item'
                  onActionClicked = {handleNewWorkItem}
                />
                <Box mt={3} mb={'120px'}>
                    <WorkItemList
                      onEdit={handleEditWorkItem}
                      onDelete={handleDeleteWorkItemClicked}
                    />
                </Box>
            </div>
          )}
          {open && workItemToEdit &&
            <EditWorkItem
              open={open}
              onActionClicked={handlerWorkItemEditorAction}
            />
          }
          <Snackbar
            open={snackbarOpen}
            autoHideDuration={800}
            onClose={() => setSnackbarOpen(false)}
            message="Work Item Saved"
          />
          {workItemToDelete && confirmDeleteOpen && (
              <ConfirmationDialog
              isOpen={confirmDeleteOpen}
              setIsOpen={setConfirmDeleteOpen}
              message="Delete Work Item?"
              description={`Are you sure you want to delete this work item? This action cannot be undone.`}
              onConfirmClicked={handleConfirmDelete}
              />
          )}
      </>
    )

}

export default WorkItemsPage
