import React from "react";
import GreetingDashboardCard from "../components/greeting/GreetingDashboardCard";
import UserWorkItemsList from "../components/user-work-items/UserWorkItemsList";
import SubHeader from "../../../shared/components/page/page-header/SubHeader";
import { Divider, useMediaQuery, useTheme } from "@mui/material";
import Grid from "@mui/material/Grid";
import EditWorkItem from "../../work-items/components/edit-work-item/EditWorkItem";
import { WorkItem } from "../../../shared/models/workItem.model";
import { useWorkItem } from "../../work-items/context/workItemContext";
import useAppStore from "../../../appStore";
import SiteHealthCard
  from "../../analytics/components/site-health/components/tabs/corp-overview/components/card/SiteHealthCard";
import DashboardSection from "../components/DashboardSection";
import Priorities from "../components/priorities/Priorities";
import ServiceScanTile from "../employee-dashboard/components/ServiceScanTile";

const SiteManagerDashboard = () => {

  const isMobile = useMediaQuery(useTheme().breakpoints.only('xs'));

  const {
    workItemToEdit, setWorkItemToEdit,
    setEditing, open, setOpen,
    setSnackbarOpen
  } = useWorkItem();

  const {selectedLocation, whiteLabelProps} = useAppStore();

  const handlerWorkItemEditorAction = (action) => {
    setEditing(false);
    setOpen(false);
    setWorkItemToEdit(new WorkItem());
    if (action === 'save') {
      setSnackbarOpen(true);
    }
  }

  return (
    <div className={'mb-10'}>
      <GreetingDashboardCard />

      <div style={{display: 'flex', flexDirection: 'column', marginTop: isMobile ? '0' : '20px'}}>

        <div style={{ width: '100%' }}>
          <div>
            <Grid container>
              {/*<Grid item xs={12} md={12}>*/}
              {/*  <DashboardSection title={"Priorities"} tile={<Priorities />} />*/}
              {/*</Grid>*/}
              <Grid item xs={12} md={12}>
                <div style={{ marginTop: '35px' }}>
                  <SubHeader title={`${whiteLabelProps.mascot_name} Score`} color="#777" showAction={false} />
                  <Divider sx={{ bgcolor: 'black', marginBottom: '15px' }} />
                  {selectedLocation.id !== 0 &&
                    <SiteHealthCard location={selectedLocation}/>
                  }
                </div>
              </Grid>
              <Grid item xs={12} md={12}>
                <UserWorkItemsList manager={true}/>
              </Grid>
            </Grid>
          </div>
        </div>

      </div>


      {open && workItemToEdit &&
        <EditWorkItem
          open={open}
          onActionClicked={handlerWorkItemEditorAction}
        />
      }
    </div>
  );
};

export default SiteManagerDashboard;