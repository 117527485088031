import React from "react";
import { getBadge } from "../../../../../shared/utils/table-modifier-util/tableDataUtil";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import useAppStore from "../../../../../appStore";

const TierTabs = ({ data, setSelectedTier, selectedTier }) => {

  const isMobile = useMediaQuery(useTheme().breakpoints.only('xs'));
  const { whiteLabelProps } = useAppStore();

  if (isMobile) {
    return (
      <div className="table-responsive" style={{ width: '100%', padding: '20px', display: selectedTier ? 'none' : '' }}>
        <table className="table table-row-dashed align-middle gs-0 gy-3 my-0">
          <tbody>
          {data.length === 0 &&
            <div style={{ flex: 1, textAlign: "center", padding: "20px", marginTop: "100px" }}>
              <p style={{
                fontSize: "20px",
                fontWeight: "semibold",
                marginBottom: "0px", paddingBottom: '0px', color: whiteLabelProps.primary_color
              }}>
                No Survey Data
              </p>
              <p style={{ marginTop: '0px', paddingTop: '0px', fontSize: "15px", color: "gray" }}>this location has no completed surveys</p>
            </div>
          }
          {data.map((tier, index) => (
            <tr key={index} style={{ cursor: "default" }}>
              <td colSpan={2}>
                <div className="d-flex justify-content-between align-items-center">
                  <div className="d-flex flex-column">
                    <a
                      className="text-gray-800 mb-1 fs-6"
                      style={{ textDecoration: "underline", cursor: "pointer" }}
                      onClick={() => setSelectedTier(tier)}
                    >
                      {tier.name}
                    </a>
                  </div>
                  <div className={getBadge(tier.avg_score)}>
                    {tier.avg_score}
                  </div>
                </div>
              </td>
            </tr>
          ))}
          </tbody>
        </table>
      </div>
    );
  }

  return (
    <div className="d-flex flex-row align-items-center">
        {data.map((tier) => (
          <Box className="d-flex flex-row" onClick={() => setSelectedTier(tier)}
               style={{cursor: 'pointer', borderBottom: selectedTier?.name === tier?.name ? '2px solid #15aa78' : '', padding: '10px', alignItems: 'center'}}>
            <div className="d-flex flex-column">
              <a
                className="mb-1 fs-6"
                style={{ cursor: "pointer", fontWeight: "bold", marginTop: '5px', marginRight: '5px', color: '#444'}}
              >
                {tier.name}
              </a>
            </div>
            <div className={getBadge(tier.avg_score)}>
              {tier.avg_score !== 0 ? tier.avg_score : 'N/A'}
            </div>
          </Box>
        ))}
    </div>
  );


};

export default TierTabs;
