import { CommandGetCases } from "./commands/cmdGetCases";
import { CommandGetDepartments } from "./commands/cmdGetDepartments";
import { CommandGetCaseTypes } from "./commands/cmdGetCaseTypes";
import { CommandCreateCase } from "./commands/cmdCreateCase";
import { CommandUpdateCase } from "./commands/cmdUpdateCase";
import { CommandGetCaseTypeCounts } from "./commands/cmdGetStatusCounts";
import { CommandDeleteCase } from "./commands/cmdDeleteCase";
import { CommandGetDetailCase } from "./commands/cmdGetDetailCase";
import { CommandGetCreatedCases } from "./commands/cmdGetCreatedCases";
import { CommandLookupCaseType } from "./commands/cmdLookupCaseType";

export class CasesService {

  async getCases(provider_id: number, employee_id: number | null, status?: string | null, case_type?: number | null, limit?: number | null, offset?: number | null) {
    const cmd = new CommandGetCases();
    const results = await cmd.run({
      provider_id,
      employee_id,
      status,
      case_type,
      limit,
      offset
    });
    return results;
  }

  async getCreatedCases(provider_id: number | undefined, employee_id: number | undefined) {
    const cmd = new CommandGetCreatedCases();
    let results = await cmd.run(provider_id, employee_id);
    return results;
  }

  async getDetailCase(itemId) {
    const cmd = new CommandGetDetailCase();
    const results = cmd.run(itemId)
    return results
  }

  async getDepartments(org) {
    const cmd = new CommandGetDepartments();
    const results = cmd.run(org)
    return results
  }

  async getCaseTypes(org, dept) {
    const cmd = new CommandGetCaseTypes();
    const results = cmd.run(org, dept)
    return results
  }

  async lookupCaseType(org_id, search) {
    const cmd = new CommandLookupCaseType();
    const results = cmd.run(org_id, search)
    return results
  }

  async getCaseTypeCounts(provider_id) {
    const cmd = new CommandGetCaseTypeCounts();
    const results = await cmd.run(provider_id);
    return results;
  }

  async createCase(item) {
    const cmd = new CommandCreateCase()
    const result = cmd.run(item)
    return result
  }

  async updateCase(item) {
    const cmd = new CommandUpdateCase();
    const updated = await cmd.run(item);
    return updated;
  }

  async deleteCase(itemId) {
    const cmd = new CommandDeleteCase();
    const result = await cmd.run(itemId);
    return result;
  }

}
