import React, { useState } from "react";
import Chart from "react-apexcharts";
import { ApexOptions } from 'apexcharts';
import { Card, CardContent, FormControlLabel, Switch } from "@mui/material";

interface ChartProps {
  chartData: (string | number)[][];
  chartTitle: string;
  primaryColor: string;
  secondaryColor?: string;
  max: number;
  sortOrder: 'asc' | 'desc' | 'title';
}

const DualBarChartComponent: React.FC<ChartProps> = ({
                                                       chartData,
                                                       chartTitle,
                                                       primaryColor,
                                                       secondaryColor = null,
                                                       max,
                                                       sortOrder='title'
                                                     }) => {

  const [showAll, setShowAll] = useState(false);
  const isDualBar = chartData[1] && chartData[1].length > 2;
  const hasData = chartData.some(row => row.slice(1).some(value => value > 0));
  const chartDataWithoutHeader = chartData[0][0] === 'Location' ? chartData.slice(1) : chartData;

  const sortChartData = (data) => {
    const nonZeroData = data.filter((row) => row[1] !== 0 || (isDualBar && row[2] !== 0));
    const zeroData = data.filter((row) => row[1] === 0 && (!isDualBar || row[2] === 0));

    let sortedNonZeroData;
    switch (sortOrder) {
      case 'asc':
        sortedNonZeroData = nonZeroData.sort((a, b) => (a[1] as number) - (b[1] as number));
        break;
      case 'desc':
        sortedNonZeroData = nonZeroData.sort((a, b) => (b[1] as number) - (a[1] as number));
        break;
      case 'title':
        sortedNonZeroData = nonZeroData.sort((a, b) => (a[0] as string).localeCompare(b[0] as string));
        break;
      default:
        sortedNonZeroData = nonZeroData;
    }

    return [...sortedNonZeroData, ...zeroData];
  };


  const filteredChartData = showAll
    ? chartDataWithoutHeader
    : chartDataWithoutHeader.filter(([_, value1, value2]) => {
      return isDualBar ? value1 !== 0 || value2 !== 0 : value1 !== 0;
    });

  const finalChartData = sortChartData(filteredChartData);

  const getSeriesData = (data, index) => {
    if (index === 1) {
      return data.map(item => item[index] as number);
    } else if (index === 2 && data.some(item => item.length > 2 && item[2] !== 0)) {
      return data.map(item => (item.length > 2 ? item[2] : null) as number);
    }
    return [];
  };

  const series = [
    {
      name: 'Average',
      data: getSeriesData(finalChartData, 1),
      fill: {
        colors: [primaryColor]
      }
    },
    {
      name: 'Joint Average',
      data: getSeriesData(finalChartData, 2),
      fill: {
        colors: [secondaryColor || '#ff4560']
      }
    }
  ].filter(series => series.data.length > 0);

  const categories = finalChartData.map(item => item[0] as string);

  const barHeight = 60;
  const chartHeight = filteredChartData.length * barHeight + (filteredChartData.length * 10) + barHeight;

  const options: ApexOptions = {
    chart: {
      type: 'bar',
      height: chartHeight,
    },
    plotOptions: {
      bar: {
        horizontal: true,
        barHeight: '65%',
        distributed: false,
        dataLabels: {
          position: 'top',
        },
      }
    },
    dataLabels: {
      enabled: true,
      offsetX: -5
    },
    title: {
      text: chartTitle,
      align: 'center',
      margin: 20,
      style: {
        fontSize: '16px',
        color: '#263238'
      }
    },
    xaxis: {
      categories: categories,
      min: 0,
      max: max,
      labels: {
        show: false
      }
    },
    yaxis: {
      labels: {
        show: true,
        maxWidth: 140,
        style: {
          cssClass: 'apexcharts-yaxis-label',
        },
        formatter: (val: number) => {
          const label = val.toString();
          const maxLength = 20;
          return label.length > maxLength ? label.substring(0, maxLength) + '...' : label;
        },
      }
    },
    colors: series.map(s => s.fill.colors[0]),
    legend: {
      show: isDualBar ? true : false,
      position: 'top',
      horizontalAlign: 'center',
      offsetX: -10
    }
  };

  return hasData ? (
    <Card variant="outlined" sx={{boxShadow: '0 1px 2px rgba(0,0,0,0.1)'}}>
      <CardContent>
        <Chart options={options} series={series} type="bar" height={chartHeight} />
        <FormControlLabel
          control={<Switch checked={showAll} onChange={() => setShowAll(!showAll)} />}
          label="Show All"
        />
      </CardContent>
    </Card>
  ) : (
    null
  );

};

export default DualBarChartComponent;
